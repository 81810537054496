// Packages
import ListItem from 'modules/Tasks/components/ListItem';
import PropTypes from 'prop-types';

// Other
import './styles.css';

const TaskList = ({ currentTaskId, isLoading, items, onChange, onItemClick }) => {

	// Loading State
	if ( isLoading )
	{
		return (
				<div className='TaskList'>
					<ListItem isLoading={isLoading}/>
				</div>
		)
	}


	// Empty State
	if ( items.length === 0 )
	{
		return (
			<h3 className='opacity italic centre white'>No tasks for this selection</h3>
		)
	}


	// Data State
	return (
		<div className='TaskList'>

			{items.map((item, index) => {

				return (
						<ListItem 
							key={index}
							{...item} 
							isLoading={false}
							onChange={() => {onChange()}}
							onItemClick={onItemClick}
							// showForm={item.id == currentTaskId ? true : false}
						/>
				)
				
			})}
	
		</div>
	)
}

TaskList.propTypes = {
	currentTaskId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	isLoading: PropTypes.bool,
	items: PropTypes.array,
	onChange: PropTypes.func,
}

TaskList.defaultProps = {
	currentTaskId: null,
	isLoading: false,
	items: [],
	onChange: () => {'onChange is not set!'},
}

export default TaskList;