// Packages
import PropTypes from 'prop-types'

// Other
import './styles.css'

const Container = ({ children, size, style }) => {

    return (
        <div className={`Container Container--size-${size}`} style={style}>

            {children}

        </div>
    )
}

Container.defaultProps = {
    size: 'lg',
    style: {}
}

Container.propTypes = {
    size: PropTypes.string,
    style: PropTypes.object
}

export default Container;