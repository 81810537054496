// Packages
import PropTypes from 'prop-types';

const DownloadIcon = ({ colour, height, onClick, style, width }) => {
    
    return(
        <div className="DownloadIcon" onClick={onClick} style={{display: 'inline-block', ...style}}>
            <svg fill={colour} id="icons" height={height} viewBox="0 0 24 24" width={width} >
                <path d="m21 19h-18a1 1 0 0 0 0 2h18a1 1 0 0 0 0-2z"/><path d="m12 2a1 1 0 0 0 -1 1v10.59l-3.29-3.3a1 1 0 0 0 -1.42 1.42l5 5a1 1 0 0 0 1.42 0l5-5a1 1 0 0 0 -1.42-1.42l-3.29 3.3v-10.59a1 1 0 0 0 -1-1z"/>
            </svg>
        </div>
    )
}

DownloadIcon.propTypes = {
    colour: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onClick: PropTypes.func,
    style: PropTypes.object,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

DownloadIcon.defaultProps = {
    colour: "currentColor",
    height: "25px",
    onClick: () => {},
    style: {},
    width: "25px", 
}

export default DownloadIcon;