// Packages
import { useState } from "react";

// Components
import AttachmentList from "../components/List";
import BTDSelectField from "components/BTDUI/fields/BTDSelectField";

// context
import { useProject } from "context/Project";

// other
import useApi from "hooks/useApi";

const AttachmentListScreen = () => {

    const { data: attachments, isLoading, onChange, setParams } = useApi('/attachments?with=project,task');
    const { projects } = useProject;

    const [createNew, setCreateNew] = useState(false);
    const [projectId, setProjectId] = useState('');

    return (
    
            <div className="AttachmentListScreen">

                    <BTDSelectField
                        className="Settings__filter"
                        label="Filter by Project"
                        onChange={(id) => {
                            setParams('&project_id=' + (id != null ? id : ''))
                            setProjectId(id)
                        }}
                        options={projects}
                        style={{marginBottom: '50px', maxWidth: '300px'}}
                        value={projectId}
                    />

                <AttachmentList 
                    createNew={createNew}
                    isLoading={isLoading}
                    items={attachments}
                    onChange={() => onChange()}
                    onSubmit={() => {
                        onChange();
                        setCreateNew(false);
                    }}
                />

            </div>
    )
}

export default AttachmentListScreen;