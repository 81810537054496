// Packages
import dayjs from 'dayjs';
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react';

const duration = require('dayjs/plugin/duration');
dayjs.extend(duration);




const Duration = props => {
    
    // Props
    const { seconds, format, date, largest } = props;

    // State
    const [secs, setSecs] = useState();

    const formatArray = format.split(" ");
    
    var string = '';

    useEffect(() => {

        if( undefined !== date && null !== date ) {

            setSecs(dayjs().unix() - dayjs(date).unix())
        }
        else {

            setSecs(seconds)
        }

    }, [date, seconds]);
    
    
    let years = Math.floor(secs / 31556952);
    let months = years > 0 ? Math.floor((secs % 31556952) / 2592000) : Math.floor(secs / 2592000);
    let days = months > 0 ? Math.floor((secs % 2592000) / 86400) : Math.floor((secs - ((years * 31556952) + (months * 2592000))) / 86400);
    let hours = days > 0 ? Math.floor((secs % 86400) / 3600) : Math.floor((secs - ((years * 31556952) + (months * 2592000) + (days * 86400))) / 3600);
    let minutes = hours > 0 ? Math.floor((secs % 3600) / 60) : Math.floor((secs - ((years * 31556952) + (months * 2592000) + (days * 86400) + (hours * 3600))) / 60);
    let s = secs % 60 > 0 ? secs % 60 : 0;

    if(formatArray.includes('Y')) {
        if( years > 0 ) {
            string += years + ' year';
            string += years > 1 ? 's ' : ' ';
            if( largest ) { return( [string]) }
        }
    }

    if(formatArray.includes('M')) {
        if( months > 0 ) {
            string += months + ' month';
            string += months > 1 ? 's ' : ' ';
            if( largest ) { return( [string]) }
        }
    }

    if(formatArray.includes('D')) {
        if( days > 0 ) {
            string += days + ' day';
            string += days > 1 ? 's ' : ' ';
            if( largest ) { return( [string]) }
        }
    }

    if(formatArray.includes('H')) {
        if( hours > 0 ) {
            string += hours + ' hour';
            string += hours > 1 ? 's ' : ' ';
            if( largest ) { return( [string]) }
        }
    }

    if(formatArray.includes('m')) {
        if( minutes > 0 ) {
            string += minutes + ' minute';
            string += minutes > 1 ? 's ' : ' ';
            if( largest ) { return( [string]) }
        }
    }

    if(formatArray.includes('s')) {
        if( s > 0 ) {
            string += s + ' second';
            string += s > 1 ? 's ' : ' ';
            if( largest ) { return( [string]) }
        }
    }

    return (
        <span className="Duration">
        {string}
        </span>
    );
}

Duration.propTypes = {
    seconds: PropTypes.number,
    format: PropTypes.string,
    date: PropTypes.string,
    largest: PropTypes.bool
}

Duration.defaultProps = {
    format: 'Y M D H m s',
    largest: false
}


export default Duration;