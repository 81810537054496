// Packages
import PropTypes from 'prop-types';


const UserInitials = ({ name }) => {

    const words = name.split(/\s+/);

    const initials = words.map((word) => {
        return word.substring(0, 1);
    })

	return (
		<>
		{initials.join('').trim().toUpperCase()}
		</>
	);

}

UserInitials.propTypes = {
	name: PropTypes.string,
};

UserInitials.defaultProps = {
	name: ''
}


export default UserInitials;