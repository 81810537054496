// Packages
import PropTypes from 'prop-types';

const HeartIcon = ({ colour, height, onClick, style, width }) => {
    
    return(
        <div className="ClearIcon" onClick={onClick} style={{display: 'inline-block', ...style}}>
            <svg width={width} height={height} viewBox="0 0 20 17" version="1.1">
                <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="Header" transform="translate(-1277.000000, -42.000000)" fill={colour} fillRule="nonzero">
                        <g id="icon-favourites" transform="translate(1277.000000, 42.000000)">
                            <path d="M14.5599829,0.000164652225 C17.5583214,0.0147861823 19.9854021,2.44574337 20,5.4489852 C20,10.9511794 10.0000255,16.4 10.0000255,16.4 C10.0000255,16.4 0,10.8710678 0,5.4489852 C0,2.43971071 2.43555356,0.000164652225 5.44001715,0.000164652225 L5.44001715,0.000164652225 C7.27914924,-0.0142012567 8.99853766,0.912323183 10.0000255,2.45745082 C11.008353,0.919838445 12.7233008,-0.00428315588 14.5599829,0.000164652225 Z" id="Path"></path>
                        </g>
                    </g>
                </g>
            </svg>
        </div>
    )
}

HeartIcon.propTypes = {
    colour: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onClick: PropTypes.func,
    style: PropTypes.object,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

HeartIcon.defaultProps = {
    colour: "currentColor",
    height: "25px",
    onClick: () => {},
    style: {},
    width: "25px", 
}

export default HeartIcon;