// Packages
import { useState } from "react";

// components
import BTDFAB from "components/BTDUI/icons/BTDFAB";
import RecurringTaskList from "../components/List";

// other
import useApi from "hooks/useApi";

const RecurringTaskListScreen = () => {

    const { data: recurringTasks, isLoading, onChange } = useApi('/recurringtasks?with=project,user,interval');
    const { data: intervals } = useApi('/intervals');

    const [createNew, setCreateNew] = useState(false);

    return (
    
            <div className="RecurringTaskListScreen">

                <RecurringTaskList 
                    createNew={createNew}
                    intervals={intervals}
                    isLoading={isLoading}
                    items={recurringTasks}
                    onChange={() => onChange()}
                    onSubmit={() => {
                        onChange();
                        setCreateNew(false);
                    }}
                />

                <BTDFAB
                    colour="#1f2124"
                    onClick={() => {setCreateNew(true)}}
                />
            </div>
    )
}

export default RecurringTaskListScreen;