// Packages
import { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone'
import PropTypes from 'prop-types';
import Axios from "axios";

// Context
import { useSnackbar } from 'context/Snackbar';

// Components
import BTDProgress from 'components/BTDUI/BTDProgress';
import TickIcon from 'components/BTDUI/icons/TickIcon';


// Others
import useApi from 'hooks/useApi';
import './styles.css';


const ImageUpload = ({ background_size, description, image_max_height, image_max_width, image_quality, image_url, label, onChange, required, style, task_id, type }) => {

    const { data: upload, post: createUpload } = useApi('/attachments ', false);
    const { data: uploadUrl, post: getUrl } = useApi('/attachments/getuploadurl', false);

    const {showSnackbar } = useSnackbar();

    // State
    const [state, setState] = useState('drop');
    const [error, setError] = useState();
    const [image, setImage] = useState();
    const [file, setFile] = useState();

    useEffect(() => {

        if( image_url ) {
            setImage(image_url);
            setState('image');
        }

    }, [image_url]);

    useEffect(() => {

        if( uploadUrl && (uploadUrl.length !== 0) && file) {

            setState('uploading');

            Axios.put(uploadUrl.url, file, {
                headers: {
                    "Content-Type": file.type
                },
            })
                .catch((error) => {
                    setError(error.response.data);
                    setState('error');
                    showSnackbar(error.response.data, 'error');
                })
                .then(async (response) => {
                    if( await createUpload({
                        'object_path': uploadUrl.object_path,
                        'extension': getFileExtension(file.name),
                        'name': file.name,
                        'mime_type': file.type,
                        'task_id': task_id
                        })
                    ) {
                        onChange(upload.id);
                        setState('drop');
                    }
                    // if(type === 'image') {
                    //     setImage(upload.url )
                    // }
                    showSnackbar('Attachment uploaded!', 'success')
            });

        }
        // eslint-disable-next-line
    }, [uploadUrl])

    const getFileExtension = (filename) => {

        const parts = filename.split('.');
        return parts[parts.length - 1];
    };

    const onDrop = async (acceptedFiles) => {

        setState('processing'); 
        setFile(acceptedFiles[0]);

        await getUrl({mime_type: acceptedFiles[0].type, ext: getFileExtension(acceptedFiles[0].name)});

    };

    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

    
    return (
        <>
        {description &&
            <p className='BTDDatepicker__description detail' dangerouslySetInnerHTML={{__html: description}}></p>
        }
        
        <div className={`ImageUpload ${isDragActive?'ImageUpload-active': ''}`} style={style}>

            {state === 'image' && 
                <label className='ImageUpload__label black medium opacity'>{label ?? ''}
                    {required &&
                        <span className='red'> *</span>
                    }
                </label>
            }   

            {state === 'drop' &&

                <div {...getRootProps({className: "ImageUpload__dropzone"})}>
                    <input {...getInputProps()} />

                    <div className='ImageUpload__dropzone__icon'></div>

                    <div className="ImageUpload__dropzone__title">{label ?? 'Image'}</div>
                    { isDragActive ?
                    <div className="ImageUpload__dropzone__text">Drag {type === 'image' ? 'image' : 'file'} here or click to upload</div>
                    :
                    <div className="ImageUpload__dropzone__text">Drag {type === 'image' ? 'image' : 'file'} here to upload</div>
                    }

                </div>
            }

            {state === 'uploading' &&

                <div className='ImageUpload__dropzone'>

                    <BTDProgress style={{marginBottom: '10px'}} />

                    <div className="ImageUpload__dropzone__title">Uploading</div>

                    <div className="ImageUpload__dropzone__text">Please wait</div>

                </div>
            }

            {state === 'processing' &&

                <div className='ImageUpload__dropzone'>

                    <BTDProgress type='circular' style={{margin: '0 auto 10px auto'}} />

                    <div className="ImageUpload__dropzone__title">Processing {type === 'image' ? 'image' : 'file'}</div>

                    <div className="ImageUpload__dropzone__text">Please wait</div>

                </div>
            }

            {state === 'error' &&

                <div className='ImageUpload__dropzone'>

                    <div className='ImageUpload__dropzone__icon ImageUpload__dropzone__icon-error'/>

                    <div className="ImageUpload__dropzone__title">Error Processing {type === 'image' ? 'image' : 'file'}</div>

                    <div className="ImageUpload__dropzone__text">{error ?? ''}</div>

                </div>
            }

            {state === 'image' &&
                <>
                {type === 'image' ?
                    <div className='ImageUpload__dropzone' style={{backgroundImage: `url(${image})`, backgroundSize: `${background_size}`, cursor: 'default', borderRadius: '8px'}}>

                        <div className='ImageUpload__dropzone__close' onClick={() => {
                            onChange({image_object_key: null, image_url: null});
                            setState('drop');
                        }}/>

                    </div>
                :
                <div className='ImageUpload__dropzone'>

                        <div className='ImageUpload__dropzone__close' onClick={() => {
                            onChange({image_object_key: null, image_url: null});
                            setState('drop');
                        }}/>

                    <TickIcon colour='#6DA726' height='25px' style={{margin: '10px auto 10px auto'}} width='25px' />

                    <div className="ImageUpload__dropzone__title">Complete!</div>

                </div>
                }

                </>
            }

        </div>
        </>
    );
}

ImageUpload.propTypes = {
    background_size: PropTypes.string,
    description: PropTypes.string,
    image_url: PropTypes.string,
    image_max_height: PropTypes.number,
    image_max_width: PropTypes.number,
    image_quality: PropTypes.number,
    label: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    required: PropTypes.bool,
    style: PropTypes.object,
    type: PropTypes.string
}

ImageUpload.defaultProps = {
    background_size: 'cover',
    description: null,
    image_url: null,
    image_max_height: 600,
    image_max_width: 800,
    image_quality: 0.8,
    label: '',
    onChange: () => {alert('onChange is not set!')},
    required: false,
    style: {},
    type: 'image'
};

export default ImageUpload;