// Packages
import ListItem from 'modules/Helpscout/components/ListItem';
import PropTypes from 'prop-types';

const HelpscoutList = ({ isLoading, items, onChange }) => {

	// Loading State
	if ( isLoading )
	{
		return (
				<div className='HelpscoutList'>
					<ListItem isLoading={isLoading}/>
				</div>
		)
	}


	// Empty State
	if ( items.length === 0 )
	{
		return (
			<h3 className='opacity italic centre white'>No tickets to show here</h3>
		)
	}


	// Data State
	return (
		<div className='HelpscoutList'>

			{items.map((item, index) => {

				return (
						<ListItem 
							key={index}
							{...item} 
							isLoading={false}
							onChange={() => {onChange()}}
						/>
				)
				
			})}

		</div>
	)
}

HelpscoutList.propTypes = {
	isLoading: PropTypes.bool,
	items: PropTypes.array,
	onChange: PropTypes.func,
}

HelpscoutList.defaultProps = {
	isLoading: false,
	items: [],
	onChange: () => {'onChange is not set!'},
}

export default HelpscoutList;