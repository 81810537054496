// Packages
import { useState } from "react";

// Components
import ProjectList from "../components/List";
import BTDFAB from "components/BTDUI/icons/BTDFAB";

// other
import useApi from "hooks/useApi";

const ChannelListScreen = () => {

    const { data: channels, isLoading, onChange } = useApi('/channels');

    const [createNew, setCreateNew] = useState(false);

    return (
    
            <div className="ChannelListScreen">

                <ProjectList 
                    createNew={createNew}
                    isLoading={isLoading}
                    items={channels}
                    onChange={() => onChange()}
                    onSubmit={() => {
                        onChange();
                        setCreateNew(false);
                    }}
                />

                <BTDFAB
                    colour="#1f2124"
                    onClick={() => {setCreateNew(true)}}
                />
            </div>
    )
}

export default ChannelListScreen;