// Packages
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Components
import ClearIcon from 'components/BTDUI/icons/ClearIcon';
import Skeleton from 'components/BTDUI/Skeleton';
import BTDTextField from 'components/BTDUI/fields/BTDTextField';

// Other
import './styles.css'
import useApi from 'hooks/useApi';

const ChannelListItem = ({ create, id, isLoading, name, onChange, onSubmit, url }) => {

	const { destroy, errors, put } = useApi('/channels/' + id, false);
	const { post } = useApi('/channels', false);

	const deleteItem = async () => {

		if( await destroy('channel', 'Channel Deleted!') ) {

			onChange();
		}
    }

	const [nameFormActive, setNameFormActive] = useState(false);
	const [urlFormActive, setUrlFormActive] = useState(false);
	const [data, setData] = useState({})
	const [changedData, setChangedData] = useState({})

	useEffect(() => {

		setData({...data, name: name, url: url});
		
	// eslint-disable-next-line
	}, [name, url])


	return (
		<>
		<div className="ChannelListItem card">

				{nameFormActive || create ?
					<form className='ChannelListItem__form' onSubmit={async (e) => {
						e.preventDefault();
						if(create) {
							if( await post({name: changedData.name}, 'Channel created!') ) {
								onSubmit();
								setNameFormActive(false);
							}
						}
						else {
							if ( await put({name: changedData.name}, 'Channel name updated!') ) {
								onChange();
								setNameFormActive(false);
							}
						}
					}}>
						<BTDTextField 
							error={errors && errors.name ? errors.name : null} 
							height='46px'
							onChange={(value) => {
								setData({...data, name: value});
								setChangedData({...changedData, name: value});
							}} 
							placeholder='Name'
							style={{margin: '10px 0', maxWidth: '500px'}}
							value={data.name ?? ''} 
						/>
					</form>
				:
					<h3 className='black semi-bold' onClick={() => setNameFormActive(true)}>{isLoading ? <Skeleton width='200px'/> : name}</h3>
				}

				
				{! create &&
					<>
					{urlFormActive ?
						<form className='ChannelListItem__form' onSubmit={async (e) => {
							e.preventDefault();
							if ( await put({url: changedData.url}, 'URL updated!') ) {
								onChange();
								setUrlFormActive(false);
							}
						}}>
							<BTDTextField 
								error={errors && errors.url ? errors.url : null} 
								height='46px'
								onChange={(value) => {
									setData({...data, url: value});
									setChangedData({...changedData, url: value});
								}} 
								placeholder='URL'
								style={{margin: '10px 0', maxWidth: '500px'}}
								value={data.url ?? ''} 
							/>
						</form>
					:
						<p className='ChannelListItem__url black' onClick={() => setUrlFormActive(true)}>{isLoading ? <Skeleton width='200px'/> : url ? url : 'Enter URL'}</p>
					}

					<div onClick={() => {deleteItem()}}><ClearIcon colour='#e02323' height='20px' style={{cursor: 'pointer'}} /></div>
					</>
				}

		</div>

		</>
	)
}

ChannelListItem.propTypes = {
	create: PropTypes.bool,
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	isLoading: PropTypes.bool,
	name: PropTypes.string,
	onChange: PropTypes.func,
	onSubmit: PropTypes.func,
	url: PropTypes.string,
}

ChannelListItem.defaultProps = {
	create: false,
	id: null,
	isLoading: false,
	name: null,
	onChange: () => {'onChange is not set!'},
	onSubmit: () => {'onSubmit is not set!'},
	url: ''
}

export default ChannelListItem;