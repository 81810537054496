// Packages 
import { useEffect, useState } from "react";
import PropTypes from 'prop-types';

const ByteConverter = ({ bytes }) => {

    const [output, setOutput] = useState();

    useEffect(() => {

        var sizes = ['B', 'KB', 'MB', 'GB', 'TB'];

        if (bytes === 0) {
            setOutput('0 B');
        } 
        else {

            var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
            
            setOutput((bytes / Math.pow(1024, i)).toFixed(2) + ' ' + sizes[i]);
        }


    }, [bytes])

    return(
        output
    )
}

ByteConverter.propTypes = {
	bytes: PropTypes.number,
}

ByteConverter.defaultProps = {
	bytes: 0
}

export default ByteConverter;