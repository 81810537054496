// Packages
import { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

// Components
import EllipsisIcon from '../icons/EllipsisIcon';

// other
import './styles.css';

const BTDMenu = ({ children, className, icon, iconStyle, style }) => {

    const [active, setActive] = useState(false);
    const [position, setPosition] = useState('right');

    const menuRef = useRef(null);

    const handleOutsideClick = (e) => {

        if (menuRef.current && ! menuRef.current.contains(e.target)) {
            setActive(false);
        }
    };

    useEffect(() => {

        if (menuRef.current) {
            const menuRect = menuRef.current.getBoundingClientRect();
            const spaceRight = window.innerWidth - menuRect.right;

            if (spaceRight < 150) {

                setPosition('left');
            }
        }
    }, [])
    

    useEffect(() => {
        if (active) {
            document.addEventListener('mousedown', handleOutsideClick);
        }
    
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [active]);

    return(
        <div className={`BTDMenu ${className}`} ref={menuRef} style={style}>
            <div className="BTDMenu__icon" onClick={() => {setActive(! active)}} style={iconStyle}>
                {icon}
            </div>
            {active &&
                <div className={`BTDMenu__menu BTDMenu__menu--position-${position}`}>
                    <div onClick={() => setActive(false)}>
                        {children}
                    </div>
                </div>
            }
        </div>
    )
}

BTDMenu.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    icon: PropTypes.object,
    iconStyle: PropTypes.object,
    style: PropTypes.object
}

BTDMenu.defaultProps = {
    children: null,
    className: '',
    icon: <EllipsisIcon />,
    iconStyle: {},
    style: {}
}

export default BTDMenu;