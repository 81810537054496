// Packages
import axios from 'axios';
import { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Components
import LoadingSpinner from 'components/BTDUI/BTDProgress';

// Other
import Config from 'other/config';
import useApi from 'hooks/useApi';

const UserContext = createContext();

export const UserProvider = ({ children }) => {

    const { data: users, isLoading: usersLoading, onChange } = useApi('/users')

    const [isLoading, setIsLoading] = useState(true);
    const [user, setUser] = useState(false);

    const navigate = useNavigate();

    const refreshUsers = () => {

        onChange();
    }

    useEffect(() => {

        const getUser = async () => 
        {
            if ( localStorage.getItem('access-token') )
            {
                try 
                {
                    const response = await axios.get(Config.API_URL + '/users/current', {
                        headers: {
                            Authorization: 'Bearer ' + localStorage.getItem('access-token')
                        }
                    })
                        
                    setUser(response.data.data);

                    // Prefill Helpscout Beacon
                    if ( window.Beacon )
                    {
                        window.Beacon('prefill', {
                            name: response.data.data.firstname + ' ' + response.data.data.lastname,
                            email: response.data.data.email
                        })
                    }
                } 
                catch (error) 
                {
                    localStorage.removeItem('access-token');
                    redirectToLogin();
                }
                finally
                {
                    setIsLoading(false);
                }
            }
            else
            {
                redirectToLogin();
            }
        }

        getUser();
    // eslint-disable-next-line
    }, [])


    const redirectToLogin = () => {

        if ( window.location.pathname !== '/login' )
        {
            navigate('/login?redirect=' + window.location.pathname);
        }
    }

    return(

        <UserContext.Provider value ={{
            user,
            users,
            usersLoading,
            refreshUsers
        }}>

            { isLoading &&
                <LoadingSpinner type="circular" style={{
                    margin: '100px auto'
                }}/>
            }

            { user !== false &&
                <>{children}</>
            }

        </UserContext.Provider>
    )
}

export const useUser = () => {
    return useContext(UserContext);
};