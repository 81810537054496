// Packages
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// Components
import Container from 'components/BTDUI/Container';
import BTDPagination from 'components/BTDUI/BTDPagination';
import TaskList from 'modules/Tasks/components/List';
import TaskViewScreen from 'modules/Tasks/screens/View';
import DefaultLayout from 'layouts/default';

// Context
import { useUser } from 'context/User';
import { useStatus } from 'context/Status';
import { useProject } from 'context/Project';

// Other
import useApi from 'hooks/useApi';
import './styles.css';

const TaskListScreen = () => {

    const [page, setPage] = useState(1);

    const location = useLocation();
    const navigate = useNavigate();

    const { data: tasks, meta, isLoading: tasksLoading, onChange, setParams } = useApi('/tasks?with=project,status,user&page=' + page + '&per_page=24');
    const { post, put } = useApi('/tasks', false);

    const [clearForm, setClearForm] = useState(false);
    const [currentTaskId, setCurrentTaskId] = useState(null);
    const [taskViewActive, setTaskViewActive] = useState(false);
    const [fromPageLoad, setFromPageLoad] = useState(true);

    const { user, users } = useUser();
    const { statuses } = useStatus();
    const { projects, projectsMeta, projectsLoading, refreshProjects } = useProject();

    useEffect(() => {

        if( user ) {
            setParams('&user_id=' + user.id);
        }

    // eslint-disable-next-line
    },[user])

    useEffect(() => {

        const searchParams = new URLSearchParams(location.search);
        const taskId = searchParams.get('task');

        if (taskId && fromPageLoad) {

            setFromPageLoad(false);
            setCurrentTaskId(taskId);
        }

    // eslint-disable-next-line
    }, [location.search]);

    useEffect(() => {

        if(currentTaskId) {

            setTaskViewActive(true);
            updateUrlWithTaskId();
        }
        else {

            setTaskViewActive(false);
        }

    // eslint-disable-next-line
    }, [currentTaskId])

    const updateUrlWithTaskId = () => {
        
        const searchParams = new URLSearchParams(location.search);
        
        searchParams.set('task', currentTaskId);

        const newUrl = `${location.pathname}?${searchParams.toString()}`;

        navigate(newUrl, { replace: true });
    };

	const removeTaskIdFromUrl = () => {

        const searchParams = new URLSearchParams(location.search);
        searchParams.delete('task');
        navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });
    };



    return(
        <DefaultLayout clearHeaderForm={clearForm} refreshTaskList={() => {onChange()}}>

            <div className="TaskListScreen__sidebar">
                <div className="TaskListScreen__sidebar__scrollDiv">

                    <div 
                        className='TaskListScreen__sidebar__item' 
                        draggable="true" 
                        onDragStart={(e) => {e.dataTransfer.setData('application/json', JSON.stringify({project_id: 1}))}}
                        onDragOver={(e) => {
                            e.preventDefault();
                            e.currentTarget.style.transform = 'scale(1.2)';
                        }}
                        onDragLeave={(e) => {
                            e.preventDefault();
                            e.currentTarget.style.transform = 'scale(1)';
                        }}
                        onDrop={async (e) => {
                            e.preventDefault();
                            const data = e.dataTransfer.getData('application/json');
                            const formData = JSON.parse(data);
                            e.currentTarget.style.transform = 'scale(1)';
                            if(formData.id) {
                                if( await put({project_id: 1}, 'Task moved to Inbox', formData.id)) {
                                    onChange();
                                    refreshProjects();
                                }
                            }
                            else {
                                if( await post({...formData, project_id: 1}, 'Task created!')) {
                                    onChange();
                                    refreshProjects();
                                }
                            setClearForm(! clearForm);
                            }
                        }}
                        onClick={() => {
                            setPage(1);
                            setParams('&project_id=1')
                        }}
                    >
                        Inbox {projectsMeta ? '(' + projectsMeta.inbox + ')' : ''}
                    </div>

                    <div 
                        className='TaskListScreen__sidebar__item'
                        draggable="true" 
                        onDragStart={(e) => {e.dataTransfer.setData('application/json', JSON.stringify({daily_update: 'yes'}))}}
                        onDragOver={(e) => {
                            e.preventDefault();
                            e.currentTarget.style.transform = 'scale(1.2)';
                        }}
                        onDragLeave={(e) => {
                            e.preventDefault();
                            e.currentTarget.style.transform = 'scale(1)';
                        }}
                        onDrop={async (e) => {
                            e.preventDefault();
                            const data = e.dataTransfer.getData('application/json');
                            const formData = JSON.parse(data);
                            e.currentTarget.style.transform = 'scale(1)';
                            if(formData.id) {
                                if( await put({daily_update: 'yes'}, 'Task assigned to Daily Update', formData.id)) {
                                    onChange();
                                }
                            }
                            else {
                                if( await post({...formData, daily_update: 'yes'}, 'Task created!')) {
                                    onChange();
                                }
                                setClearForm(! clearForm);
                            }
                        }}
                        onClick={() => {
                            setPage(1);
                            setParams('&daily_update=yes')
                        }}
                        style={{marginBottom: '60px'}}
                        >
                        Daily Update
                    </div>

                    {users &&
                        <div style={{marginBottom: '60px'}}>

                            {users.map((user) => (
                                <div 
                                    className='TaskListScreen__sidebar__item'
                                    draggable="true" 
                                    key={user.id}
                                    onDragStart={(e) => {e.dataTransfer.setData('application/json', JSON.stringify({user_id: user.id}))}}
                                    onDragOver={(e) => {
                                        e.preventDefault();
                                        e.currentTarget.style.transform = 'scale(1.2)';
                                    }}
                                    onDragLeave={(e) => {
                                        e.preventDefault();
                                        e.currentTarget.style.transform = 'scale(1)';
                                    }}
                                    onDrop={async (e) => {
                                        e.preventDefault();
                                        const data = e.dataTransfer.getData('application/json');
                                        const formData = JSON.parse(data);
                                        e.currentTarget.style.transform = 'scale(1)';
                                        if(formData.id) {
                                            if( await put({user_id: user.id}, 'Task assigned to ' + user.name, formData.id)) {
                                                onChange();
                                            }
                                        }
                                        else {
                                            if( await post({...formData, user_id: user.id}, 'Task created!')) {
                                                onChange();
                                            }
                                            setClearForm(! clearForm);
                                        }
                                    }}
                                    onClick={() => {
                                        setPage(1);
                                        setParams('&user_id=' + user.id)
                                    }}
                                    style={{backgroundColor: user.colour}}
                                >
                                    {user.name}
                                </div>
                            ))}
                        </div>

                }

                    {! projectsLoading &&
                        projects.map((project) => {
                            if(project.id != 1) {
                                return(
                                <div 
                                    className='TaskListScreen__sidebar__item'
                                    draggable="true" 
                                    key={project.id}
                                    onDragStart={(e) => {e.dataTransfer.setData('application/json', JSON.stringify({project_id: project.id}))}}
                                    onDragOver={(e) => {
                                        e.preventDefault();
                                        e.currentTarget.style.transform = 'scale(1.2)';
                                    }}
                                    onDragLeave={(e) => {
                                        e.preventDefault();
                                        e.currentTarget.style.transform = 'scale(1)';
                                    }}
                                    onDrop={async (e) => {
                                        e.preventDefault();
                                        const data = e.dataTransfer.getData('application/json');
                                        const formData = JSON.parse(data);
                                        e.currentTarget.style.transform = 'scale(1)';
                                        if(formData.id) {
                                            if( await put({project_id: project.id}, 'Task moved to ' + project.name, formData.id)) {
                                                onChange();
                                            }
                                        }
                                        else {
                                            if( await post({...formData, project_id: project.id}, 'Task created!')) {
                                                onChange();
                                            }
                                            setClearForm(! clearForm);
                                        }
                                    }}
                                    onClick={() => {
                                        setPage(1);
                                        setParams('&project_id=' + project.id)
                                    }}
                                    style={{backgroundColor: project.colour}}
                                >
                                    {project.name}
                                </div>
                                )
                            }
                            else {
                                return(<></>)
                            }
                        })
                    }

                </div>
            </div>

            <div className="TaskListScreen__content">

                <Container>

                    <TaskList
                        items={tasks}
                        isLoading={tasksLoading}
                        onChange={onChange}
                        onItemClick={(id) => {setCurrentTaskId(id)}}
                    />

                    {meta && meta.total > meta.per_page &&
                        <BTDPagination
                            currentPage={meta.current_page} 
                            totalPages={meta.last_page}
                            onChange={(value) => {
                                setPage(value);
                                onChange();
                            }}
                            style={{margin: '30px auto', width: 'max-content'}}
                        />
                    }	
                </Container>

                {taskViewActive &&
                    <TaskViewScreen
                        taskId={currentTaskId}
                        onClose={() => {
                            setTaskViewActive(false);
                            removeTaskIdFromUrl();
                            setCurrentTaskId(null);
                        }} 
                        onSubmit={() => {
                            setTaskViewActive(false);
                            onChange();
                            setCurrentTaskId(null);
                        }} 
                    />
                }
            </div>

            <div className='TaskListScreen__statuses'>
                <div className="TaskListScreen__sidebar__scrollDiv">

                    {statuses &&
                        statuses.map((status) => (
                            <div 
                                className='TaskListScreen__statuses__item'
                                draggable="true" 
                                key={status.id}
                                onDragStart={(e) => {e.dataTransfer.setData('application/json', JSON.stringify({status_id: status.id}))}}
                                onDragOver={(e) => {
                                    e.preventDefault();
                                    e.currentTarget.style.transform = 'scaleX(1.1)';
                                }}
                                onDragLeave={(e) => {
                                    e.preventDefault();
                                    e.currentTarget.style.transform = 'scaleX(1)';
                                }}
                                onDrop={async (e) => {
                                    e.preventDefault();
                                    const data = e.dataTransfer.getData('application/json');
                                    const formData = JSON.parse(data);
                                    e.currentTarget.style.transform = 'scaleX(1)';
                                    if(formData.id) {
                                        if( await put({status_id: status.id}, 'Task moved to ' + status.name, formData.id)) {
                                            onChange();
                                        }
                                    }
                                    else {
                                        if(await post({...formData, status_id: status.id}, 'Task created!')) {
                                            onChange();
                                        }
                                        setClearForm(! clearForm);
                                    }
                                }}
                                onClick={() => {
                                    setPage(1);
                                    setParams('&status_id=' + status.id)
                                }}
                                style={{backgroundColor: status.colour}}
                            >
                                <div
                                    className="TaskListScreen__statuses__item__topTriangle"
                                />
                                    {status.name}
                                <div
                                    className="TaskListScreen__statuses__item__bottomTriangle"
                                    style={{borderTop: `20px solid ${status.colour}`}}
                                />
                            </div>
                        ))
                    }
                </div>
            </div>

        </DefaultLayout>
    )

}

export default TaskListScreen;