// Packages
import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { memo } from 'react';
import PropTypes from 'prop-types';

// Context
import { useSnackbar } from 'context/Snackbar';

// Components
import UserInitials from 'components/BTDUI/UserInitials';
import BTDButton from 'components/BTDUI/BTDButton';
import BTDSkeleton from 'components/BTDUI/Skeleton';
import LogoutIcon from 'components/BTDUI/icons/LogoutIcon';

// other
import './styles.css';
import Config from 'other/config';
import axios from 'axios';

const Avatar = ({ size, user, withDropDown, withName }) => {

	const navigate = useNavigate();

	const [dropdownActive, setDropdownActive] = useState(false);

	const menuRef = useRef(null);

    const handleOutsideClick = (e) => {

        if (menuRef.current && ! menuRef.current.contains(e.target)) {
            setDropdownActive(false);
        }
    };

	useEffect(() => {
        if (dropdownActive) {
            document.addEventListener('mousedown', handleOutsideClick);
        }
    
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [dropdownActive]);

	const token = localStorage.getItem('access-token');

	const { showSnackbar } = useSnackbar();

	const logout = async () => {
		
		try {
			const response = await axios.post(Config.API_URL + '/logout', {}, {
				headers: {
					Authorization: 'Bearer ' + token
				}});
	
			if (response.status >= 200 && response.status < 300) {

				localStorage.removeItem('access-token');

				showSnackbar('Logged out', 'info')
				
				navigate('/login');
			}
			
		} catch (error) {

			console.error('Logout failed', error);
	
			if (error.response.status === 401) {
				
				showSnackbar('Unauthorised', 'error');

				navigate('/login');

			} else {

				showSnackbar(error.response.message, 'error')
			}
		}
	}

    return(
        <div className='Avatar' ref={menuRef}>
			{user ? 
				<div
					alt={user.name ?? ''} 
					className='Avatar__image'
					onClick={() => {
						setDropdownActive(! dropdownActive);
					}}
					style={{
						backgroundImage: `url('${user.avatar_url}')`,
						backgroundColor: user.colour,
						height: `${size}px`,
						width: `${size}px`,
					}} 
				>
					{! user.avatar_url && 
					
						<UserInitials name={user.name ?? ''} />
					}

				</div>
				:
				<BTDSkeleton  width="43px" height="43px" />
			}

			{withName &&
				<p className='Avatar__name semi-bold black'>{user.name ?? ''}</p>
			}

			{withDropDown && dropdownActive && 
				<>
				<div className="Avatar__dropdown">

					<div className="Avatar__dropdown__triangle" />

					<p className='Avatar__dropdown__name bold black'>{user.name ?? ''}</p>

					<BTDButton
						label='Sign Out'
						onClick={logout}
						startIcon={<LogoutIcon colour='#fff' height='22px' style={{marginRight: '8px'}} width='22px' />}
						style={{margin: '0 auto'}}
					/>

						
				</div>
				</>
			}

        </div>
    )
}

Avatar.propTypes = {
	active: PropTypes.bool,
    className: PropTypes.string,
	size: PropTypes.number,
	toggleOverlay: PropTypes.func,
    user: PropTypes.object,
    withDropDown: PropTypes.bool,
    withName: PropTypes.bool
}

Avatar.defaultProps = {
	active: false,
    className: '',
	size: 43,
	toggleOverlay: () => {alert('toggleOverlay is not set!')},
    user: null,
    withDropDown: true,
    withName: false
}

export default memo(Avatar);