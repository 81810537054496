// Packages
import { createContext, useContext, useEffect, useState } from 'react';

// Other
import useApi from 'hooks/useApi';

const TimerContext = createContext();

export const TimerProvider = ({ children }) => {

    // State
    const [ task, setTask ] = useState(null);
    const [ time, setTime ] = useState(null);
    
    const { data, get, onChange } = useApi('/timer');
    const { data: stopData, post: stopTimer } = useApi('/timer/stop', false);
    const { data: pauseData, post: pauseTimer } = useApi('/timer/pause', false);
    const { data: continueData, post: continueTimer } = useApi('/timer/continue', false);
    const { data: startData, post: startTimer } = useApi('/timer/start', false);

    useEffect(() => {

        if( data.task ) {

            setTask(data.task);
        }
        else {

            setTask(null);
        }

        if( data.time ) {

            setTime(data.time);
        }

        else {
            setTime(null);
        }
    }, [data])

    const checkTimer = () => {

            get('', '')
            
            setTimeout(() => {

                checkTimer();

            }, 60000);
            
    }

    useEffect(() => {
        checkTimer();
        // eslint-disable-next-line
    }, [startData, pauseData, continueData, stopData]);

    const stop = async () => {
        
        await stopTimer();
        onChange();
        setTask(null);
        setTime(null);
    }

    const pause = async () => {
        
        await pauseTimer();
        setTime(null);
        onChange();
    }

    const cont = async () => {
        
        await continueTimer();
        onChange();
    }

    const start = async (id) => {
        
        await startTimer({task_id: id})
        onChange();
    }

    return (
        <TimerContext.Provider value={{
            show: task !== null,
            task: task,
            time: time,
            stop: stop,
            pause: pause,
            continueTimer: cont,
            start: start
        }}>
        {children}
        </TimerContext.Provider>
    );

}

export const useTimer = () => {
    return useContext(TimerContext);
};