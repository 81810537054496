// Components
import BTDProgress from 'components/BTDUI/BTDProgress';
import MainRoutes from 'other/mainroutes';

// Context
import { useUI } from 'context/UI';

// Other
import 'assets/styles.css';

function App() {

	const { getLoading } = useUI();

	return (
	<>
		{getLoading() &&
		<>
			<div style={{ 
				left: '0',
				position: 'absolute', 
				top: '0',
				width: '100%', 
				}}
			>
				<BTDProgress />
			</div>
		</>
		}

		<MainRoutes/>
    </>
	);
}

export default App;
