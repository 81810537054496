// Packages
import PropTypes from 'prop-types';

const CloseIcon = ({ colour, height, onClick, style, width }) => {
    
    return(
        <div className="CloseIcon" onClick={onClick} style={{display: 'inline-block', ...style}}>
            <svg height={height} viewBox="-0.5 0 25 25" fill={colour} width={width} xmlns="http://www.w3.org/2000/svg">
                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> 
                    <path d="M3 21.32L21 3.32001" stroke={colour} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path> <path d="M3 3.32001L21 21.32" stroke={colour} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path> 
                </g>
            </svg>
        </div>
    )
}

CloseIcon.propTypes = {
    colour: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onClick: PropTypes.func,
    style: PropTypes.object,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

CloseIcon.defaultIcon = {
    colour: "currentColor",
    height: "25px",
    onClick: () => {},
    style: {},
    width: "25px", 
}

export default CloseIcon;