// Packages
import { useState } from "react";

// components
import UserList from "../components/List";
import BTDFAB from "components/BTDUI/icons/BTDFAB";

// Context
import { useUser } from "context/User";

const UserListScreen = () => {

    const { users, usersLoading, refreshUsers } = useUser();

    const [createNew, setCreateNew] = useState(false);

    return (
    
            <div className="UserListScreen">

                <UserList 
                    createNew={createNew}
                    isLoading={usersLoading}
                    items={users}
                    onChange={() => refreshUsers()}
                    onSubmit={() => {
                        refreshUsers();
                        setCreateNew(false);
                    }}
                />

                <BTDFAB
                    colour="#1f2124"
                    onClick={() => {setCreateNew(true)}}
                />
            </div>
    )
}

export default UserListScreen;