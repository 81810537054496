// Packages
import { useState } from "react";
import PropTypes from 'prop-types';

// Components
import BTDButton from "components/BTDUI/BTDButton";
import BTDTextareaField from "components/BTDUI/fields/BTDTextareaField";
import { Editor } from '@tinymce/tinymce-react';

// other
import Config from 'other/config';
import './styles.css';

const TextEditor = ({ description, error, label, onChange, reference, required, style, tabIndex, value, withHeader }) => {

    const [showCodeEditor, setShowCodeEditor] = useState(false);

    return(
        <div 
            className='BTDEditor'
            style={style}
            tabIndex={tabIndex ?? 0} 
        >

            {withHeader &&
                <div className="BTDEditor__header">
            
                    <p className='form__label black medium opacity'>{label}
                        {required &&
                            <span className='red'> *</span>
                        }
                    </p>

                    <BTDButton
                        label='Toggle Source Code'
                        onClick={() => {setShowCodeEditor(!showCodeEditor)}}
                        size="small"
                        type='outlined'
                    />

                </div>
            }
            
            {description &&
                <p className='detail' dangerouslySetInnerHTML={{__html: description}}></p>
            }

            {showCodeEditor ?
                <BTDTextareaField
                    error={error ?? null} 
                    description={description ?? null } 
                    label={label}
                    onChange={(e) => onChange(e.target.value)} 
                    rows={5}
                    value={value ?? ''} 
                />
            :
            <>
                <Editor
                    apiKey={Config.EDITOR_API_KEY}
                    error={error ?? false} 
                    helperText={error ?? '' } 
                    init={{
                        height: 200,
                        menubar: false,
                        plugins: [
                            'advlist', 'autolink', 'lists', 'link', 'charmap',
                            'anchor', 'searchreplace', 'visualblocks', 'fullscreen',
                            'insertdatetime', 'media'
                        ],
                        toolbar:
                        'bold italic underline forecolor | link | alignleft aligncenter ' +
                        'alignright | bullist numlist outdent indent | ' +
                        'removeformat | ',
                        content_style: 'body { font-family:Poppins,Arial,sans-serif; font-size:16px }',
                        setup: function (editor) {
                            if (reference) {reference.current = editor;}
                        }
                    }}
                    onEditorChange={onChange} 
                    value={value ?? ''}
                /> 
                {error &&
                    <p className='BTDTextareaField__error'>{error}</p>
                }
            </>
            }
        </div>
    )
}

TextEditor.propTypes = {
    description: PropTypes.string,
    error: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    label: PropTypes.string,
    onChange: PropTypes.func,
    required: PropTypes.bool,
    style: PropTypes.object,
    tabIndex: PropTypes.number,
    value: PropTypes.string,
    withHeader: PropTypes.bool
}

TextEditor.defaultProps = {
    description: null,
    errors: null,
    label: '',
    onChange: () => {alert('onChange in not set!')},
    reference: null,
    required: false,
    style: {},
    tabIndex : 0,
    value: '',
    withHeader: true
}

export default TextEditor;