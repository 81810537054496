// Packages
import PropTypes from 'prop-types';

// Components
import ClearIcon from 'components/BTDUI/icons/ClearIcon';
import Skeleton from 'components/BTDUI/Skeleton';
import BTDDatepicker from 'components/BTDUI/fields/BTDDatepicker';
import Duration from 'components/BTDUI/Duration';
import UserInitials from 'components/BTDUI/UserInitials';
import BTDMenu from 'components/BTDUI/BTDMenu';

// Context
import { useUser } from 'context/User';
import { useProject } from 'context/Project';

// Other
import './styles.css'
import useApi from 'hooks/useApi';
import { Link } from 'react-router-dom';

const TimeListItem = ({ ended_at, id, isLoading, is_running, onChange, project, seconds, started_at, task, user }) => {

	const { users } = useUser();
	const { projects } = useProject();

	const { destroy, errors, put } = useApi('/times/' + id, false);

	const deleteItem = async () => {

		if( await destroy('time', 'Time Deleted!') ) {

			onChange();
		}
    }

	return (
		<>
		<div className="TimeListItem card">

				<div>
					<BTDMenu 
						icon={<p  style={{color: project ? project.colour : '#777'}}>{project ? project.name : 'Select project'}</p>} 
						iconStyle={{justifyContent: 'left', width: 'auto'}}
						style={{}}
					>
						{projects && projects.map((project) => (
							<p
								style={{color: project.colour ?? '#777'}}
								onClick={async () => {
									if ( await put({project_id: project.id}, 'Time updated!') ) {
										onChange();
									}
								}}
							>
								{project.name ?? ''}
							</p>
						))}
					</BTDMenu>

					<Link className='black' to={'/?task=' + (task ? task.id : '')}>{task ? task.name : ''}</Link>
				</div>

				<BTDDatepicker
					error={errors && errors.started_at ? errors.started_at : null}
					label='Started At'
					onChange={async (value) => {
						if(await put({started_at: value}, 'Time updated!')) {
							onChange();
						}
					}}
					height='20px'
					style={{cursor: 'pointer', margin: '0 10px 0 0'}}
					value={started_at}
				/>
				<BTDDatepicker
					error={errors && errors.ended_at ? errors.ended_at : null}
					label='Ended At'
					onChange={async (value) => {
						if(await put({ended_at: value}, 'Time updated!', id)) {
							onChange();
						}
					}}
					height='20px'
					style={{cursor: 'pointer', margin: '0 10px 0 0'}}
					value={ended_at}
				/>

				<Duration seconds={seconds} />

				<BTDMenu 
						icon={<div className='TimeListItem__user white' style={{backgroundColor: user ? user.colour : '#777'}}>{user ? <UserInitials name={user ? user.name : ''}/> : <Skeleton borderRadius='50%' height='35px' width='35px' />}</div>}
						iconStyle={{height: 'auto', width: 'auto'}}
					>
						{users && users.map((user) => (
							<p 
								key={user.id} 
								onClick={async () => {
									if( await put({user_id: user.id}, 'Time assigned!') ) {
										onChange();
									}
								}}
								>
								{user.name}
							</p>
						))}
					</BTDMenu>
				
				<div onClick={() => {deleteItem()}}><ClearIcon colour='#e02323' height='20px' style={{cursor: 'pointer'}} /></div>

		</div>

		</>
	)
}

TimeListItem.propTypes = {
	create: PropTypes.bool,
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	isLoading: PropTypes.bool,
	name: PropTypes.string,
	onChange: PropTypes.func,
	onSubmit: PropTypes.func,
	project: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	size: PropTypes.number,
	task: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
}

TimeListItem.defaultProps = {
	create: false,
	id: null,
	isLoading: false,
	name: null,
	onChange: () => {'onChange is not set!'},
	onSubmit: () => {'onSubmit is not set!'},
	project:{},
	size: 0,
	task: {}
}

export default TimeListItem;