// Packages
import PropTypes from 'prop-types'

// Components
import UserInitials from 'components/BTDUI/UserInitials';

// other
import './styles.css';

const SearchResult = ({ id, name, onClose, project, status, user }) => {

    return(
        <div 
            className='SearchResult' 
            onClick={() => {
                window.location.href = `/?task=${id}`;
                onClose();
            }}
            style={{border: `1px solid ${project ? project.colour : '#ddd'}`}}
        >

            <div className='SearchResult__top'>
                <span style={{color: project ? project.colour : '#777'}}>{project ? project.name : ''}</span>
                <span className='SearchResult__top__status' style={{backgroundColor: status ? status.colour : '#777'}}>{status ? status.abbr : ''}</span>
                <span className='SearchResult__top__user' style={{backgroundColor: user ? user.colour : '#777'}}>{user ? <UserInitials name={user.name}/> : ''}</span>
            </div>

            <p className="SearchResult__top__name black">{name ?? ''}</p>

        </div>
    )
}

SearchResult.propTypes = {
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    name: PropTypes.string,
    onClose: PropTypes.func,
    project: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    status: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    user: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
}

SearchResult.defaultProps = {
    id: null,
    name: '',
    onClose: () => {alert('onCose is not set!')},
    project: {},
    status: {},
    user: {}
}

export default SearchResult;