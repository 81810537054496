// Packages
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Other
import './styles.css';

const BTDTextField = ({ autocomplete, description, endIcon, error, height, label, labelColour, onChange, placeholder, required, startIcon, style, tabIndex, type, value, width }) => {

    const [active, setActive] = useState(false);

    useEffect(() => {

        if( value != null && value != '' ) {

            setActive(true);
        }
        else {

            setActive(false)
        }
    }, [value])


    return(
        <div>
        {description &&
            <p className='BTDTextField__description detail' dangerouslySetInnerHTML={{__html: description}}></p>
        }
        <div className={`BTDTextField ${width} `} style={{height: height, ...style}}>
            <label className={`BTDTextField__label ${active ? 'active' : ''} ${startIcon ? 'startIcon' : ''}`} style={{backgroundColor: labelColour}}>
                <span className='BTDTextField__label__wrapper'>
                    {label ?? ''}
                    {required &&
                        <span className='red'>{<>&nbsp;</>}*</span>
                    }
                </span>

            </label>
            
            {startIcon &&
                <div className="BTDTextField__startIcon">
                    {startIcon}
                </div>
            }

            <input 
                autoComplete={autocomplete ? '' : 'new-password'}
                className={`BTDTextField__input ${error ?? ''} ${startIcon ? 'startIcon' : ''}`} 
                onChange={(e) => {onChange(e.target.value)}} 
                placeholder={placeholder}
                required={required} 
                style={{height: height}}
                tabIndex={tabIndex ?? 0}
                type={type ?? 'text'}
                value={value} 
            />
            {endIcon &&
                <div className='BTDTextField__endIcon'/>
            }
        </div>
            {error &&
                <p className='BTDTextField__error'>{error}</p>
            }
    </div>
    )
}

BTDTextField.propTypes = {
    autocomplete: PropTypes.bool,
    description: PropTypes.string,
    endIcon: PropTypes.object,
    error: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    height: PropTypes.string,
    label: PropTypes.string,
    labelColour: PropTypes.string,
    onChange: PropTypes.func,
    required: PropTypes.bool,
    startIcon: PropTypes.object,
    style: PropTypes.object,
    tabIndex: PropTypes.number,
    type: PropTypes.string,
    value: PropTypes.string,
    width: PropTypes.string
}

BTDTextField.defaultProps = {
    autocomplete: false,
    description: null,
    endIcon: null,
    error: null,
    height: 'auto',
    label: '',
    labelColour: '#fff',
    onChange: () => {alert('onChange is not set!')},
    required: false,
    startIcon: null,
    style: {},
    tabIndex : 0,
    type: 'text',
    value: '',
    width: 'large'
}

export default BTDTextField;                                         