// Packages
import { useEffect, useState } from "react";

// Components
import TimeList from "../components/List";
import BTDSelectField from "components/BTDUI/fields/BTDSelectField";
import BTDDatepicker from "components/BTDUI/fields/BTDDatepicker";

// context
import { useProject } from "context/Project";

// other
import useApi from "hooks/useApi";
import './styles.css';
import Duration from "components/BTDUI/Duration";
import BTDPagination from "components/BTDUI/BTDPagination";

const TimeListScreen = () => {

    const [page, setPage] = useState(1);

    const { data: times, isLoading, meta, onChange, setParams } = useApi('/times?with=project,task&page=' + page + '&per_page=100');

    const { projects } = useProject();

    const [projectId, setProjectId] = useState('');
    const [startedAt, setStartedAt] = useState('');
    const [endedAt, setEndedAt] = useState('');
    const [totalTime, setTotalTime] = useState(null);

    useEffect(() => {

		if( times ) {

			let total = 0;
				for( let i = 0; i < times.length; i++ ) {
					total += times[i].seconds;
				}
				setTotalTime(total);
		}
	}, [times]);

    return (
    
            <div className="TimeListScreen">

                <div className="TimeListScreen__filters">

                    <BTDSelectField
                        className="Settings__filter"
                        label="Filter by Project"
                        onChange={(id) => {
                            setParams('&project_id=' + (id != null ? id : '') + '&started_at=' + (startedAt != null ? startedAt : '') + '&ended_at=' + (endedAt != null ? endedAt : ''));
                            setProjectId(id);
                        }}
                        options={projects}
                        style={{margin: '0 20px 0 0', maxWidth: '300px'}}
                        value={projectId}
                    />

                    <BTDDatepicker
                        className="Settings__filter"
                        label="Started At"
                        onChange={(value) => {
                            setParams('&project_id=' + (projectId != null ? projectId : '') + '&started_at=' + (startedAt != null ? startedAt : '') + '&ended_at=' + (endedAt != null ? endedAt : ''));
                            setStartedAt(value)
                        }}
                        style={{margin: '0 20px 0 0', maxWidth: '300px'}}
                        type="datetime"
                        value={startedAt}
                    />

                    <BTDDatepicker
                        className="Settings__filter"
                        label="Ended At"
                        onChange={(value) => {
                            setParams('&project_id=' + (projectId != null ? projectId : '') + '&started_at=' + (startedAt != null ? startedAt : '') + '&ended_at=' + (endedAt != null ? endedAt : ''));
                            setEndedAt(value)
                        }}
                        style={{margin: '0 20px 0 0', maxWidth: '300px'}}
                        type="datetime"
                        value={endedAt}
                    />
                    <h3 className="white">{totalTime && <><span>Total Time: </span> <Duration seconds={totalTime}/></>}</h3>
                </div>


                <TimeList 
                    isLoading={isLoading}
                    items={times}
                    onChange={() => onChange()}
                />

                {meta && meta.total > meta.per_page &&
                    <BTDPagination
                        currentPage={meta.current_page} 
                        totalPages={meta.last_page}
                        onChange={(value) => {
                            setPage(value);
                            onChange();
                        }}
                        style={{margin: '30px auto', width: 'max-content'}}
                    />
                }	

            </div>
    )
}

export default TimeListScreen;