// Packages
import { useState } from "react";

// components
import StatusList from "../components/List";
import BTDFAB from "components/BTDUI/icons/BTDFAB";

// Context
import { useStatus } from "context/Status";

const StatusListScreen = () => {

    const { statuses, statusesLoading, refreshStatuses } = useStatus();

    const [createNew, setCreateNew] = useState(false);

    return (
    
            <div className="StatusListScreen">

                <StatusList 
                    createNew={createNew}
                    isLoading={statusesLoading}
                    items={statuses}
                    onChange={() => refreshStatuses()}
                    onSubmit={() => {
                        refreshStatuses();
                        setCreateNew(false);
                    }}
                />

                <BTDFAB
                    colour="#1f2124"
                    onClick={() => {setCreateNew(true)}}
                />
            </div>
    )
}

export default StatusListScreen;