// Packages
import { createContext, useContext } from 'react';

// Other
import useApi from 'hooks/useApi';

const StatusContext = createContext();

export const StatusProvider = ({ children }) => {

    const { data: statuses, isLoading: statusesLoading, onChange: refreshStatuses } = useApi('/statuses')

    return(

        <StatusContext.Provider value ={{
            statuses,
            statusesLoading,
            refreshStatuses
        }}>
            {children}
        </StatusContext.Provider>
    )
}

export const useStatus = () => {
    return useContext(StatusContext);
};