// Packages
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

// Components
import Header from "components/Header"
import Footer from "components/Footer"

// Context
import { useUser } from 'context/User';
import { useStatus } from 'context/Status';

// other
import './styles.css';

const DefaultLayout = ({ children, clearHeaderForm, refreshTaskList }) => {

    const [clearForm, setClearForm] = useState(false);

    const { user, users } = useUser();
    const { statuses } = useStatus();

    useEffect(() => {

		setClearForm(! clearForm);
        
    // eslint-disable-next-line
	},[clearHeaderForm])


    return(
        <div className="DefaultLayout">

            <Header statuses={statuses} users={users} user={user} clearForm={clearForm} onChange={() => refreshTaskList()}/>

                <div className="DefaultLayout__main">

                    {children}

                </div>
    
            <Footer/>
        </div>
    )
}


DefaultLayout.propTypes = {
	children: PropTypes.node,
}

DefaultLayout.defaultProps = {
	children: null,
}

export default DefaultLayout;