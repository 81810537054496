// Packages
import { createContext, useContext } from 'react';

// Other
import useApi from 'hooks/useApi';

const ProjectContext = createContext();

export const ProjectProvider = ({ children }) => {

    const { data: projects, isLoading: projectsLoading, meta: projectsMeta, onChange: refreshProjects } = useApi('/projects')

    return(

        <ProjectContext.Provider value ={{
            projects,
            projectsLoading,
            projectsMeta,
            refreshProjects
        }}>
            {children}
        </ProjectContext.Provider>
    )
}

export const useProject = () => {
    return useContext(ProjectContext);
};