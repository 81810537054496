// Packages
import { useState } from "react";

// components
import ProjectList from "../components/List";
import BTDFAB from "components/BTDUI/icons/BTDFAB";

// Context
import { useProject } from "context/Project";


const ProjectListScreen = () => {

    const { projects, projectsLoading, refreshProjects } = useProject();

    const [createNew, setCreateNew] = useState(false);

    return (
    
            <div className="ProjectListScreen">

                <ProjectList 
                    createNew={createNew}
                    isLoading={projectsLoading}
                    items={projects}
                    onChange={() => refreshProjects()}
                    onSubmit={() => {
                        refreshProjects();
                        setCreateNew(false);
                    }}
                />

                <BTDFAB
                    colour="#1f2124"
                    onClick={() => {setCreateNew(true)}}
                />
            </div>
    )
}

export default ProjectListScreen;