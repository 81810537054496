// Packages
import PropTypes from 'prop-types';
import { useEffect } from 'react';

// Context
import { useSnackbar } from 'context/Snackbar';

// Other
import useApi from 'hooks/useApi';


const DownloadPDF = ({ children, default_filename, id, text }) => {

    const { showSnackbar } = useSnackbar();
	
	const { data: tempUrl, get } = useApi(`/attachments/${id}/download`, false);

	useEffect(() => {

		if( tempUrl && tempUrl.length != 0 ) {

			const download = async () => {

				try {
					// Make an API call to fetch the PDF data
					const response = await fetch(tempUrl.url);
			
					if (! response.ok) {
			
						showSnackbar('Failed to download file', 'error');
					}
					const contentDisposition = response.headers.get('Content-Disposition');
					let filename = default_filename;
			
					if (contentDisposition && contentDisposition.includes('attachment')) {
			
						const filenameMatch = contentDisposition.match(/filename="?(.+)"?/);
			
						if (filenameMatch.length === 2) {
			
							filename = filenameMatch[1];
						}
					}
					const data = await response.blob(); // Convert the response to a Blob
			
					// Trigger the download
					const obrjectUrl = window.URL.createObjectURL(data);
					const a = document.createElement('a');
					a.style.display = 'none';
					a.href = obrjectUrl;
					a.download = filename;
					document.body.appendChild(a);
					a.click();
					window.URL.revokeObjectURL(obrjectUrl);
			
				} catch (error) {
					showSnackbar(error.message, 'error');
				}
			}

			download();
		}
	// eslint-disable-next-line
	}, [tempUrl])


    return(
        <div onClick={() => get()}>{children}{text}</div>
    )
}

DownloadPDF.propTypes = {
    children: PropTypes.node,
    default_filename: PropTypes.string,
    text: PropTypes.string,
    url: PropTypes.string,
}

DownloadPDF.defaultProps = {
    children: null,
    default_filename: 'file',
    text: '',
    url: ''
}

export default DownloadPDF;