// Packages
import { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

// Components
import ClearIcon from 'components/BTDUI/icons/ClearIcon';
import Skeleton from 'components/BTDUI/Skeleton';
import BTDColourPicker from 'components/BTDUI/fields/BTDColourPicker';
import BTDTextField from 'components/BTDUI/fields/BTDTextField';

// Other
import './styles.css'
import useApi from 'hooks/useApi';
import BTDButton from 'components/BTDUI/BTDButton';
import HeartIcon from 'components/BTDUI/icons/HeartIcon';
import HeartOutlineIcon from 'components/BTDUI/icons/HeartOutlineIcon';

const ProjectListItem = ({ create, colour, id, is_favourite, isLoading, onChange, onSubmit, name }) => {

	const { destroy, errors, put } = useApi('/projects/' + id, false);
	const { post } = useApi('/projects', false);

	const deleteItem = async () => {

		if( await destroy('project', 'Project Deleted!') ) {

			onChange();
		}
    }

	const [nameFormActive, setNameFormActive] = useState(false);
	const [data, setData] = useState({})
	const [changedData, setChangedData] = useState({})
	const [colourActive, setColourActive] = useState(false);
    const [position, setPosition] = useState('right');

    const menuRef = useRef(null);

	useEffect(() => {

		setData({...data, name: name, colour: colour, is_favourite: is_favourite});

	// eslint-disable-next-line
	}, [name, colour, is_favourite])

    const handleOutsideClick = (e) => {

        if (menuRef.current && ! menuRef.current.contains(e.target)) {
            setColourActive(false);
        }
    };

    useEffect(() => {

        if (menuRef.current) {
            const menuRect = menuRef.current.getBoundingClientRect();
            const spaceRight = window.innerWidth - menuRect.right;

            if (spaceRight < 150) {

                setPosition('left');
            }
        }
    }, [])
    

    useEffect(() => {

        if (colourActive) {
            document.addEventListener('mousedown', handleOutsideClick);
        }
    
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };

    }, [colourActive]);

	return (
		<>
		<div className="ProjectListItem card">

				{nameFormActive || create ?
					<form className='ProjectListItem__form' onSubmit={async (e) => {
						e.preventDefault();
						if(create) {
							if( await post({name: changedData.name}, 'Project created!') ) {
								onSubmit();
								setNameFormActive(false);
							}
						}
						else {
							if ( await put({name: changedData.name}, 'Project updated!') ) {
								onChange();
								setNameFormActive(false);
							}
						}
					}}>
						<BTDTextField 
							error={errors && errors.name ? errors.name : null} 
							height='46px'
							onChange={(value) => {
								setData({...data, name: value});
								setChangedData({...changedData, name: value});
							}} 
							placeholder='Name'
							style={{margin: '10px 0', maxWidth: '500px'}}
							value={data.name ?? ''} 
						/>
					</form>
				:
					<h3 className='black semi-bold' onClick={() => setNameFormActive(true)}>{isLoading ? <Skeleton width='200px'/> : name}</h3>
				}

				{! create &&
				<>
					<div className="ProjectListItem__colour" ref={menuRef} >
						<div className={`ProjectListItem__colour__icon`} onClick={() => {setColourActive(! colourActive)}} style={{backgroundColor: colour ? colour : '#fff'}}/>
							{colourActive &&
								<div className={`ProjectListItem__colour__menu ProjectListItem__colour__menu--position-${position}`}>
									<form onSubmit={async (e) => {
										e.preventDefault();
										if ( await put({colour: changedData.colour}, 'Colour updated!') ) {
											onChange();
											setColourActive(false)
										}
									}}>
										<BTDColourPicker 
											label='Colour'
											onChange={(value) => {
												setData({...data, colour: value});
												setChangedData({...changedData, colour: value});
											}} 
											value={data.colour}
											style={{marginBottom: '10px'}}
										/>
										<BTDButton
											label='save'
											size='small'
											style={{margin: '0 auto'}}
											type='submit'
										/>

									</form>
								</div>
							}
					</div>

					<div className='ProjectListItem__favourite' onClick={async () => {
						if ( await put({is_favourite: data.is_favourite == 'y' ? 'n' : 'y'}, data.is_favourite == 'y' ? 'Removed from favourites' : 'Added to favourites!') ) {
							onChange();
						}
					}}>
						{data.is_favourite == 'y' ?
							<HeartIcon colour='#e02323' height='20px' width='20px'/>
						:
							<HeartOutlineIcon height='20px' width='20px'/>
						}
					</div>


					<div onClick={() => {deleteItem()}}><ClearIcon colour='#e02323' height='20px' style={{cursor: 'pointer'}} /></div>
				</>
				}

		</div>

		</>
	)
}

ProjectListItem.propTypes = {
	create: PropTypes.bool,
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	is_favourite: PropTypes.string,
	isLoading: PropTypes.bool,
	name: PropTypes.string,
	onChange: PropTypes.func,
	onSubmit: PropTypes.func,
}

ProjectListItem.defaultProps = {
	create: false,
	id: null,
	is_favourite: 'n',
	isLoading: false,
	name: null,
	onChange: () => {'onChange is not set!'},
	onSubmit: () => {'onSubmit is not set!'},
}

export default ProjectListItem;