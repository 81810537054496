// Packages
import ListItem from 'modules/RecurringTasks/components/ListItem';
import PropTypes from 'prop-types';

const RecurringTaskList = ({ createNew, intervals, isLoading, items, onChange, onSubmit }) => {

	// Loading State
	if ( isLoading )
	{
		return (
				<div className='RecurringTaskList'>
					<ListItem isLoading={isLoading}/>
				</div>
		)
	}


	return (
		<>
		{items.length === 0 ?
			<h3 className='opacity italic centre white' style={{marginBottom: '30px'}}>No recurring tasks to show here</h3>
			:

		<div className='RecurringTaskList'>

			{items.map((item, index) => {

				return (
						<ListItem 
							key={index}
							{...item} 
							intervals={intervals}
							isLoading={false}
							onChange={() => {onChange()}}
						/>
				)
				
			})}
		</div>
		}

			{createNew &&
				<ListItem
					create={true}
					isLoading={false}
					onSubmit={() => onSubmit()}
				/>
			}
		</>
	)

}

RecurringTaskList.propTypes = {
	createNew: PropTypes.bool,
	isLoading: PropTypes.bool,
	intervals: PropTypes.array,
	items: PropTypes.array,
	onChange: PropTypes.func,
	onSubmit: PropTypes.func,
}

RecurringTaskList.defaultProps = {
	createNew: false,
	isLoading: false,
	items: [],
	intervals: [],
	onChange: () => {'onChange is not set!'},
	onSubmit: () => {'onSubmit is not set!'},
}

export default RecurringTaskList;