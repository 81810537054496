// Packages
import ListItem from 'modules/Attachments/components/ListItem';
import PropTypes from 'prop-types';

const AttachmentList = ({ createNew, isLoading, items, onChange, onSubmit }) => {

	// Loading State
	if ( isLoading )
	{
		return (
				<div className='AttachmentList'>
					<ListItem isLoading={isLoading}/>
				</div>
		)
	}


	// Empty State
	if ( items.length === 0 )
	{
		return (
			<h3 className='opacity italic centre white'>No attachments to show here</h3>
		)
	}


	// Data State
	return (
		<div className='AttachmentList'>

			{items.map((item, index) => {

				return (
						<ListItem 
							key={index}
							{...item} 
							isLoading={false}
							onChange={() => {onChange()}}
						/>
				)
				
			})}

			{createNew &&
				<ListItem
					create={true}
					isLoading={false}
					onSubmit={() => onSubmit()}
				/>
			}
	
		</div>
	)
}

AttachmentList.propTypes = {
	createNew: PropTypes.bool,
	isLoading: PropTypes.bool,
	items: PropTypes.array,
	onChange: PropTypes.func,
	onSubmit: PropTypes.func,
}

AttachmentList.defaultProps = {
	createNew: false,
	isLoading: false,
	items: [],
	onChange: () => {'onChange is not set!'},
	onSubmit: () => {'onSubmit is not set!'},
}

export default AttachmentList;