// Packages
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Components
import ClearIcon from 'components/BTDUI/icons/ClearIcon';
import Skeleton from 'components/BTDUI/Skeleton';
import BTDTextField from 'components/BTDUI/fields/BTDTextField';
import ByteConverter from 'components/BTDUI/ByteConverter';

// Other
import './styles.css'
import useApi from 'hooks/useApi';
import DownloadIcon from 'components/BTDUI/icons/DownloadIcon';
import DownloadPDF from 'components/BTDUI/DownloadPDF';

const AttachmentListItem = ({ create, id, isLoading, onChange, onSubmit, project, name, size, task }) => {

	const { destroy, errors, put } = useApi('/attachments/' + id, false);

	const deleteItem = async () => {

		if( await destroy('attachment', 'Attachment Deleted!') ) {

			onChange();
		}
    }

	const [nameFormActive, setNameFormActive] = useState(false);
	const [data, setData] = useState({})
	const [changedData, setChangedData] = useState({})

	useEffect(() => {

		setData({...data, name: name});
		
	// eslint-disable-next-line
	}, [name])


	return (
		<>
		<div className="AttachmentListItem card">

				{nameFormActive ?
					<form className='AttachmentListItem__form' onSubmit={async (e) => {
						e.preventDefault();
						if ( await put({name: changedData.name}, 'Name updated!') ) {
							onChange();
							setNameFormActive(false);
						}
					}}>
						<BTDTextField 
							error={errors && errors.name ? errors.name : null} 
							height='46px'
							onChange={(value) => {
								setData({...data, name: value});
								setChangedData({...changedData, name: value});
							}} 
							style={{margin: '10px', maxWidth: '500px'}}
							value={data.name ?? ''} 
						/>
					</form>
				:
					<p className='black semi-bold' onClick={() => setNameFormActive(true)}>{isLoading ? <Skeleton width='200px'/> : name}</p>
				}

				<div>
					<p style={{color: project ? project.colour : '#444'}}>{project ? project.name : ''}</p>
					<p>{task ? task.name : ''}</p>
				</div>

				<div>
					<ByteConverter bytes={size ? size : 0} />
				</div>

				<DownloadPDF id={id} default_filename={name}>
					<DownloadIcon colour='#888' height='20px' style={{cursor: 'pointer'}} />
				</DownloadPDF>
				
				<div onClick={() => {deleteItem()}}><ClearIcon colour='#e02323' height='20px' style={{cursor: 'pointer'}} /></div>

		</div>

		</>
	)
}

AttachmentListItem.propTypes = {
	create: PropTypes.bool,
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	isLoading: PropTypes.bool,
	name: PropTypes.string,
	onChange: PropTypes.func,
	onSubmit: PropTypes.func,
	project: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	size: PropTypes.number,
	task: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
}

AttachmentListItem.defaultProps = {
	create: false,
	id: null,
	isLoading: false,
	name: null,
	onChange: () => {'onChange is not set!'},
	onSubmit: () => {'onSubmit is not set!'},
	project:{},
	size: 0,
	task: {}
}

export default AttachmentListItem;