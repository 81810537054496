// Packages
import { useState } from 'react';
import PropTypes from 'prop-types';

// Components
import Container from 'components/BTDUI/Container';
import ProjectListScreen from 'modules/Projects/screens';
import UserListScreen from 'modules/Users/screens';
import StatusListScreen from 'modules/Statuses/screens';
import AttachmentListScreen from 'modules/Attachments/screens';
import DefaultLayout from './default';
import ChannelListScreen from 'modules/Channels/screens';
import RecurringTaskListScreen from 'modules/RecurringTasks/screens';
import TimeListScreen from 'modules/Times/screens';

// other
import './styles.css';

const SettingsLayout = () => {

    const [screen, setScreen] = useState('projects');

    return(
        <DefaultLayout>

            <div className="SettingsLayout__sidebar">

                <p className={`SettingsLayout__sidebar__item white ${screen === 'attachments' ? 'active' : ''}`} onClick={() => setScreen('attachments')}>Attachments</p>
                <p className={`SettingsLayout__sidebar__item white ${screen === 'channels' ? 'active' : ''}`} onClick={() => setScreen('channels')}>Channels</p>
                <p className={`SettingsLayout__sidebar__item white ${screen === 'projects' ? 'active' : ''}`} onClick={() => setScreen('projects')}>Projects</p>
                <p className={`SettingsLayout__sidebar__item white ${screen === 'statuses' ? 'active' : ''}`} onClick={() => setScreen('statuses')}>Statuses</p>
                <p className={`SettingsLayout__sidebar__item white ${screen === 'users' ? 'active' : ''}`} onClick={() => setScreen('users')}>Users</p>
                <p className={`SettingsLayout__sidebar__item white ${screen === 'recurring' ? 'active' : ''}`} onClick={() => setScreen('recurring')}>Recurring Tasks</p>
                <p className={`SettingsLayout__sidebar__item white ${screen === 'times' ? 'active' : ''}`} onClick={() => setScreen('times')}>Times</p>
            </div>

            <div className="SettingsLayout__main">

                <Container>

                    {screen === 'attachments' && <AttachmentListScreen/>}
                    {screen === 'channels' && <ChannelListScreen/>}
                    {screen === 'projects' && <ProjectListScreen/>}
                    {screen === 'statuses' && <StatusListScreen/>}
                    {screen === 'users' && <UserListScreen/>}
                    {screen === 'recurring' && <RecurringTaskListScreen/>}
                    {screen === 'times' && <TimeListScreen/>}
                    
                </Container>
            </div>
        </DefaultLayout>

    )
}


SettingsLayout.propTypes = {
	children: PropTypes.node,
}

SettingsLayout.defaultProps = {
	children: null,
}

export default SettingsLayout;