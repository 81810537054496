// Packages
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

// Components
import BTDButton from 'components/BTDUI/BTDButton';
import Skeleton from 'components/BTDUI/Skeleton';

// Other
import './styles.css'
import useApi from 'hooks/useApi';

const HelpscoutListItem = ({ created_at, customer_firstname, customer_lastname, email, id, imported, isLoading, number, onChange, subject, task }) => {

	const { post } = useApi('/helpscout/' + id, false);

	const navigate = useNavigate();

	return (
		<div className="HelpscoutListItem card">

			<p className='opacity'>{isLoading ? <Skeleton width='200px'/> : '#' + number}</p>

			<p className='opacity'>{created_at ? dayjs(created_at.date).format('DD/MM/YYYY') : <Skeleton width='60px'/>}</p>

			<p className='black semi-bold'>{isLoading ? <Skeleton width='200px'/> : subject}</p>

			<p className=''>{isLoading ? <Skeleton width='200px'/> : customer_firstname + ' ' + customer_lastname + ' (' + email + ')'}</p>

			{!isLoading &&
				<BTDButton
					colour={imported ? 'green' : 'orange'}
					label={imported ? 'View' : 'Import'}
					onClick={async () => {
						if( imported ) {
							navigate('/?task=' + task.id)
						}
						else {
							post({}, 'Ticket imported!', '/?task=') 
						}
					}}
					size='small'
				/>
			}
		</div>
	)
}

HelpscoutListItem.propTypes = {
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	isLoading: PropTypes.bool,
	name: PropTypes.string,
	onChange: PropTypes.func,
}

HelpscoutListItem.defaultProps = {
	id: null,
	isLoading: false,
	name: null,
	onChange: () => {'onChange is not set!'},
}

export default HelpscoutListItem;