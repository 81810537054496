// Packages
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom';

// Context
import { useUser } from 'context/User';

// Components
import Avatar from 'components/BTDUI/Avatar';
import Container from 'components/BTDUI/Container';
import SearchIcon from 'components/BTDUI/SearchButton';
import Search from 'components/BTDUI/Search';
import Overlay from 'components/BTDUI/Overlay';
import BTDTextField from 'components/BTDUI/fields/BTDTextField';
import UserInitials from 'components/BTDUI/UserInitials';

// other
import './styles.css';
import oLogo from 'assets/o-colour-line.svg';
import vLogo from 'assets/v-colour-line.svg';
import Gear from 'assets/gear.svg';
import useApi from 'hooks/useApi';
import Timer from 'components/Timer';

const Header = ({ statuses, users, clearForm, onChange }) => {

	const [overlayActive, setOverlayActive] = useState(false);
	const [searchActive, setSearchActive] = useState(false);
	const [avatarActive, setAvatarActive] = useState(false);
	const [name, setName] = useState('');
	const [statusId, setStatusId] = useState(null);
	const [userId, setUserId] = useState(null);

	const { user } = useUser();

	const { errors, post } = useApi('/tasks', false);

	useEffect(() => {

		setName('');
		setStatusId(2);
		if( user ) {
			setUserId(user.id);
		}
		else {
			setUserId(null);
		}

	// eslint-disable-next-line
	},[clearForm])

	return (
		<>
		<header className="Header">

			<Container>
				<Link className='white' to='/'>
					<h1 className='Header__logo'><img className='Header__vlogo' src={vLogo} alt="V Logo"/>el<img className='Header__ologo' src={oLogo} alt="V Logo"/>city</h1>
				</Link> 

				<div className='Header__form' draggable="true" onDragStart={(e) => {e.dataTransfer.setData('application/json', JSON.stringify({name: name, status_id: statusId, user_id: userId}))}}>
					<form onSubmit={async (e) => {
							e.preventDefault();
							if( await post({name: name, status_id: statusId, user_id: userId}, 'Task created!') ) {
								setName('');
								setStatusId(2);
								if( user ) {
									setUserId(user.id);
								}
								else {
									setUserId(null);
								}
								onChange();
							}
						}}>
						<div className='Header__form__buttons'>

							<ul className='Header__form__statuses'>
								{statuses && statuses.map((status, index) => {
									if(status.abbr != 'C') {
										return(
											<label key={index} className="Header__form__statuses__status" style={{backgroundColor: status.colour}}>
												<input
													checked={statusId === status.id}
													className="Header__form__statuses__status__input"
													onChange={() => setStatusId(status.id)}
													style={{backgroundColor: status.colour}}
													type="radio"
													value={status.id}
													/>
													<div
														className="Header__form__statuses__status__leftTriangle"
													/>
														{status.abbr}
													<div
														className="Header__form__statuses__status__rightTriangle"
														style={{borderLeft: `8px solid ${status.colour}`}}
													/>
											</label>
										)
									}
									else {
										return(<></>)
									}
								})}
							</ul>

							<ul className='Header__form__users'>
								{users && users.map((user, index) => {
									return(
										<label key={index} className="Header__form__users__user" style={{backgroundColor: user.colour ? user.colour : '#777'}}>
											<input
												checked={userId === user.id}
												className="Header__form__users__user__input"
												onChange={() => setUserId(user.id)}
												type="radio"
												value={user.id}
												/>
											<UserInitials name={user.name}/>
										</label>
									)
								})}
							</ul>
						</div>
						<BTDTextField 
							error={errors && errors.name ? errors.name : null} 
							height='22px'
							onChange={(value) => {
								setName(value);
							}} 
							placeholder='Task Name'
							style={{marginBottom: '15px'}}
							value={name ?? ''} 
						/>
					</form>
				</div>

				<Timer/>

				<div className="Header__right">
					<Link to='/helpscout' className='Header__helpscout'></Link>
					<div className="Header__Search" onClick={() => {
						if( ! searchActive ) {
							setOverlayActive(true);
							setAvatarActive(false);
							setSearchActive(true);
						}
						else {
							setOverlayActive(false);
							setSearchActive(false);
						}
						}}>
						<SearchIcon />
					</div>
					<Link to='/settings' className='Header__settings'>
						<img src={Gear} alt='Settings icon'/>
					</Link>
					<div className="Header__Avatar">
						<Avatar 
							active={avatarActive}
							user={user ?? null}
						/>
					</div>
				</div>

			</Container>

		</header>

		{searchActive &&
		
			<Search onClose={() => {
				setSearchActive(false);
				setOverlayActive(false);
			}} />
		}

		{overlayActive &&
		
			<Overlay onClick={() => {
				setAvatarActive(false);
				setSearchActive(false);
				setOverlayActive(false);
			}} />
		}

		</>
	)
}

Header.propTypes = {
	statuses: PropTypes.oneOfType([PropTypes.object, PropTypes.array]), 
	users: PropTypes.oneOfType([PropTypes.object, PropTypes.array]), 
	clearForm: PropTypes.func, 
	onChange: PropTypes.func,
}

Header.defaultProps = {
	statuses: [],
	user: [],
	clearForm: () => {alert('clearForm is not set!')},
	onChange: () => {alert('onChange is not set!')},
}

export default Header;