// Packages
import { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

// Components
import ClearIcon from 'components/BTDUI/icons/ClearIcon';
import Skeleton from 'components/BTDUI/Skeleton';
import BTDColourPicker from 'components/BTDUI/fields/BTDColourPicker';
import BTDTextField from 'components/BTDUI/fields/BTDTextField';

// Other
import './styles.css'
import useApi from 'hooks/useApi';
import BTDButton from 'components/BTDUI/BTDButton';

const UserListItem = ({ create, id, colour, email, isLoading, onChange, onSubmit, name, username }) => {

	const { destroy, errors, put } = useApi('/users/' + id, false);
	const { post } = useApi('/users', false);

	const deleteItem = async () => {

		if( await destroy('user', 'User Deleted!') ) {

			onChange();
		}
    }

	const [nameFormActive, setNameFormActive] = useState(false);
	const [usernameFormActive, setUsernameFormActive] = useState(false);
	const [emailFormActive, setEmailFormActive] = useState(false);
	const [passwordFormActive, setPasswordFormActive] = useState(false);
	const [data, setData] = useState({})
	const [changedData, setChangedData] = useState({})
	const [colourActive, setColourActive] = useState(false);
    const [colourPosition, setColourPosition] = useState('right');
    const [passwordPosition, setPasswordPosition] = useState('right');
	const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const colourRef = useRef(null);
    const passwordRef = useRef(null);

	useEffect(() => {

		setData({...data, colour: colour, name: name, email: email, username: username});

	// eslint-disable-next-line
	}, [colour, name, email, username])

    const handleOutsideClick = (e) => {

        if (colourRef.current && ! colourRef.current.contains(e.target)) {
            setColourActive(false);
        }
        if (passwordRef.current && ! passwordRef.current.contains(e.target)) {
            setPasswordFormActive(false);
        }
    };

    useEffect(() => {

        if (colourRef.current) {
            const menuRect = colourRef.current.getBoundingClientRect();
            const spaceRight = window.innerWidth - menuRect.right;

            if (spaceRight < 150) {

                setColourPosition('left');
            }
        }
        if (passwordRef.current) {
            const menuRect = passwordRef.current.getBoundingClientRect();
            const spaceRight = window.innerWidth - menuRect.right;

            if (spaceRight < 150) {

                setPasswordPosition('left');
            }
        }
    }, [])
    

    useEffect(() => {

        if (colourActive || passwordFormActive) {
            document.addEventListener('mousedown', handleOutsideClick);
        }
    
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };

    }, [colourActive, passwordFormActive]);

	return (
		<>
		<div className="UserListItem card">

				{nameFormActive || create ?
					<form className='UserListItem__form' onSubmit={async (e) => {
						e.preventDefault();
						if(create) {
							if( await post({name: changedData.name}, 'User created!') ) {
								onSubmit();
								setNameFormActive(false);
							}
						}
						else {
							if ( await put({name: changedData.name}, 'User updated!') ) {
								onChange();
								setNameFormActive(false);
							}
						}
					}}>
						<BTDTextField 
							error={errors && errors.name ? errors.name : null} 
							height='46px'
							onChange={(value) => {
								setData({...data, name: value});
								setChangedData({...changedData, name: value});
							}} 
							placeholder='name'
							style={{margin: '10px 0', maxWidth: '500px'}}
							value={data.name ?? ''} 
						/>
					</form>
				:
					<h3 className='UserListItem__text black semi-bold' onClick={() => setNameFormActive(true)}>{isLoading ? <Skeleton width='200px'/> : name}</h3>
				}

				{! create &&
				<>
				{emailFormActive ?
					<form className='UserListItem__form' onSubmit={async (e) => {
						e.preventDefault();
						if ( await put({email: changedData.email}, 'Email updated!') ) {
							onChange();
							setEmailFormActive(false);
						}
					}}>
						<BTDTextField 
							error={errors && errors.email ? errors.email : null} 
							height='46px'
							onChange={(value) => {
								setData({...data, email: value});
								setChangedData({...changedData, email: value});
							}} 
							placeholder='Email address'
							style={{margin: '10px 0', maxWidth: '500px'}}
							value={data.email ?? ''} 
						/>
					</form>
				:
					<p className='UserListItem__text black' onClick={() => setEmailFormActive(true)}>{isLoading ? <Skeleton width='200px'/> : email ? email : 'Enter email'}</p>
				}

				{usernameFormActive ?
					<form className='UserListItem__form' onSubmit={async (e) => {
						e.preventDefault();
						if ( await put({username: changedData.username}, 'Username updated!') ) {
							onChange();
							setUsernameFormActive(false);
						}
					}}>
						<BTDTextField 
							error={errors && errors.username ? errors.username : null} 
							height='46px'
							onChange={(value) => {
								setData({...data, username: value});
								setChangedData({...changedData, username: value});
							}} 
							placeholder='username'
							style={{margin: '10px 0', maxWidth: '500px'}}
							value={data.username ?? ''} 
						/>
					</form>
				:
					<p className='UserListItem__text black' onClick={() => setUsernameFormActive(true)}>{isLoading ? <Skeleton width='200px'/> : username ? username : 'Set username'}</p>
				}


				<div className="UserListItem__colour" ref={colourRef} >
					<div className={`UserListItem__colour__icon`} onClick={() => {setColourActive(! colourActive)}} style={{backgroundColor: colour ? colour : '#fff'}}>{colour ? '' : 'Select colour'}</div>
						{colourActive &&
							<div className={`UserListItem__colour__menu UserListItem__colour__menu--position-${colourPosition}`}>
								<form onSubmit={async (e) => {
										e.preventDefault();
										if ( await put({colour: changedData.colour}, 'Colour updated!') ) {
											onChange();
											setColourActive(false)
										}
									}}>
										<BTDColourPicker 
											label='Colour'
											onChange={(value) => {
												setData({...data, colour: value});
												setChangedData({...changedData, colour: value});
											}} 
											value={data.colour}
											style={{marginBottom: '10px'}}
										/>
										<BTDButton
											label='save'
											size='small'
											style={{margin: '0 auto'}}
											type='submit'
										/>

									</form>
							</div>
						}
				</div>

				<div className="UserListItem__password" ref={passwordRef} >
					<BTDButton 
						label='Reset Password'
						type='outlined'
						onClick={() => setPasswordFormActive(true)}
						size='small'
					/>

					{passwordFormActive &&
						<form className={`UserListItem__password__menu UserListItem__password__menu--position-${passwordPosition}`} onSubmit={async (e) => {
							e.preventDefault();
							if ( await put({password: newPassword, confirm_password: confirmPassword}, 'Password updated!') ) {
								onChange();
								setPasswordFormActive(false);
							}
						}}>
							<BTDTextField 
								error={errors && errors.password ? errors.password : null} 
								height='46px'
								onChange={(value) => {setNewPassword(value)}} 
								placeholder='Password'
								style={{maxWidth: '230px'}}
								type='password'
								value={newPassword} 
							/>
							<BTDTextField 
								error={errors && errors.confirm_password ? errors.confirm_password : null} 
								height='46px'
								onChange={(value) => {setConfirmPassword(value)}} 
								placeholder='Confirm Password'
								style={{marginBottom: '5px', maxWidth: '230px'}}
								type='password'
								value={confirmPassword} 
							/>
							{newPassword.length !== 0 &&  confirmPassword.length !== 0 && newPassword !== confirmPassword &&
								<p className='UserListItem__password__menu__message red'>Passwords must match!</p>
							}
							{newPassword.length !== 0 &&  confirmPassword.length !== 0 && newPassword === confirmPassword &&
								<p className='UserListItem__password__menu__message green'>Passwords match!</p>
							}
							<BTDButton
								disabled={newPassword.length !== 0 &&  confirmPassword.length !== 0 && newPassword === confirmPassword ? false : true}
								label='Submit'
								formButton={true}
								size='small'
								style={{margin: '20px auto 0 auto'}}
								type='submit'
							/>
						</form>
					}
				</div>

				<div onClick={() => {deleteItem()}}><ClearIcon colour='#e02323' height='20px' style={{cursor: 'pointer'}} /></div>
			</>
			}

		</div>

		</>
	)
}

UserListItem.propTypes = {
	create: PropTypes.bool,
	email: PropTypes.string,
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	isLoading: PropTypes.bool,
	name: PropTypes.string,
	onChange: PropTypes.func,
	onSubmit: PropTypes.func,
	username: PropTypes.string
}

UserListItem.defaultProps = {
	create: false,
	email: '',
	id: null,
	isLoading: false,
	name: null,
	onChange: () => {'onChange is not set!'},
	onSubmit: () => {'onSubmit is not set!'},
	usename: ''
}



export default UserListItem;