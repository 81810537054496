// Packages
import { Routes, Route } from "react-router-dom"

// Routes
import LoginRoutes from "modules/Login/routes"

// Context
import { UserProvider } from 'context/User';
import { StatusProvider } from 'context/Status';
import { ProjectProvider } from 'context/Project';
import { TimerProvider } from 'context/Timer';

// Components
import SettingsLayout from "layouts/settings";
import TaskListScreen from "modules/Tasks/screens/List";
import HelpscoutListScreen from "modules/Helpscout/screens";




// Wrapper component for protected routes
const ProtectedRoutes = () => (
	<UserProvider>
		<StatusProvider>
			<ProjectProvider>
				<TimerProvider>
					<Routes>
						<Route path="/helpscout/*" element={<HelpscoutListScreen />} />
						<Route path='/settings/*' element={<SettingsLayout/>}/>
						<Route index element={<TaskListScreen />} />
					</Routes>
				</TimerProvider>
			</ProjectProvider>
		</StatusProvider>
	</UserProvider>
);

const MainRoutes = () => {
	return (
		<Routes>
			{/* Login Routes (outside of UserProvider) */}
			<Route path="/login/*" element={<LoginRoutes />} />
	
			{/* Protected Routes */}
			<Route path="/*" element={<ProtectedRoutes />} />
		</Routes>
	);
};

export default MainRoutes;