// Packages
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

// Context
import { useSnackbar } from 'context/Snackbar';
import { useUI } from 'context/UI';

// Components
import LoginLayout from 'modules/Login/layouts';
import BTDTextField from 'components/BTDUI/fields/BTDTextField';
import BTDButton from 'components/BTDUI/BTDButton';

// Other
import './styles.css';
import config from 'other/config';
import RacingCar from 'assets/racing-car.svg';

const LoginScreen = () => {

    const navigate = useNavigate();
    const { showSnackbar } = useSnackbar();
    const { setLoading } = useUI();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const url = new URL(window.location.href);

    const submit = async () => {

        setLoading(true);

        try {

            const response = await axios.post(config.API_URL + '/sanctum/token', {email: email, password: password});

            if (response.status >= 200 && response.status < 300) {

                localStorage.setItem('access-token', response.data.access_token);

                let redirect = decodeURIComponent(url.searchParams.get('redirect'));

                let redirectUrl = redirect != null && redirect !== 'null' ? redirect : '/';

                navigate(redirectUrl);
            }
            else {

                showSnackbar(response.error, 'error');
            }

        } catch (error) {

            showSnackbar('Login failed', error.response.message);
        }

        setLoading(false);

    }

    return(
        <LoginLayout>

            <div className='LoginScreen'>

                <div className='LoginScreen__header'>
                    <p className='white medium'>Velocity</p>
                    <img src={RacingCar} alt='Racing car logo'/>
                </div>

                <div className='LoginScreen__main'>

                    <form onSubmit={(e) => {
                        e.preventDefault();
                        submit();
                    }}>

                        <BTDTextField
                            autocomplete={true}
                            label='Your Email'
                            onChange={(value) => setEmail(value)}
                            style={{backgroundColor: '#fff'}}
                            tabIndex={1}
                            value={email}
                        />
                        <BTDTextField
                            autocomplete={true}
                            label='Your Password'
                            onChange={(value) => setPassword(value)}
                            type='password'
                            style={{backgroundColor: '#fff'}}
                            tabIndex={2}
                            value={password}
                        />

                        <BTDButton
                        colour='green'
                            formButton={true}
                            label='Login >'
                            style={{margin: '0 auto'}}
                            type='submit'
                        />
                    </form>

                </div>
            </div>

        </LoginLayout>
    )
}

export default LoginScreen;