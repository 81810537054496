// Packages
import { useState, useEffect } from 'react';

// Context
import { useTimer } from 'context/Timer';


// Assets
import './styles.css';


const Timer = () => {

    const { time, show, task, pause, continueTimer, stop } = useTimer();

    const [ seconds, setSeconds] = useState(null);

    useEffect(() => {

        if ( time )
        {
            setSeconds(time.seconds);
        }

    }, [time]);

    if ( ! show )
    {
        return (<></>);
    }

    return (
        <div className="Timer">
            <div className="Timer__header">
                <div className="Timer__task">
                    { task &&
                        <div class='black opacity' onClick={() => { window.location.href = `/?task=${task.id}`}}>{task.name}</div>
                        // <Link to={`/?task=${task.id}`}>{task.name}</Link>
                    }
                </div>
            </div>
            <div className="Timer__body">
                <div className="Timer__clock">
                    <div className={`Timer__clock__header ${time ? 'Timer__clock__header--running' : ''}`}/>
                    <div className="Timer__clock__body">
                        {time &&
                            <>
                            {Math.floor(seconds/60)}
                            <span className="Timer__clock__unit">m</span>
                            </>
                        }

                    </div>
                </div>
                <div className="Timer__footer">
                    { time &&
                        <div onClick={() => {
                            pause();
                        }} className="Timer__button Timer__button--pause"/>
                    }


                    { ! time &&
                        <div onClick={() => {
                            continueTimer();
                        }} className="Timer__button Timer__button--start"/>
                    }

                    <div onClick={() => {
                        stop();
                    }} className="Timer__button Timer__button--stop"/>
                </div>
            </div>

        </div>
    );
}

export default Timer;