// Components
import DefaultLayout from "layouts/default";
import HelpscoutList from "../components/List";
import Container from "components/BTDUI/Container";

// other
import useApi from "hooks/useApi";
import './styles.css';

const HelpscoutListScreen = () => {

    const { data: tickets, isLoading, onChange } = useApi('/helpscout');

    return (
        <DefaultLayout>

            <div className="HelpscoutListScreen">

                <Container>

                    <h1 className="HelpscoutListScreen__title">Helpscout Inbox</h1>

                    <HelpscoutList 
                        isLoading={isLoading}
                        items={tickets}
                        onChange={() => onChange()}
                    />
                </Container>

            </div>
        </DefaultLayout>
    )
}

export default HelpscoutListScreen;