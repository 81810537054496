// Packages
import PropTypes from 'prop-types';

const LogoutIcon = ({ colour, height, onClick, style, width }) => {
    
    return(
        <div className="LogoutIcon" onClick={onClick} style={{display: 'inline-block', ...style}}>
            <svg height={height} viewBox="0 0 512 512" width={width} version="1.1" xmlns="http://www.w3.org/2000/svg">
                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="logout" transform="translate(0, 0.85)" fill={colour} fillRule="nonzero">
                        <path d="M255.15,467.775 L63.787,467.775 C52.05,467.775 42.525,458.249 42.525,446.513 L42.525,63.788 C42.525,52.051 52.051,42.526 63.787,42.526 L255.15,42.526 C266.908,42.526 276.412,33.022 276.412,21.264 C276.412,9.506 266.908,0 255.15,0 L63.787,0 C28.619,0 0,28.62 0,63.788 L0,446.512 C0,481.68 28.619,510.299 63.787,510.299 L255.15,510.299 C266.908,510.299 276.412,500.795 276.412,489.037 C276.412,477.279 266.908,467.775 255.15,467.775 Z" id="Path"></path>
                        <path d="M505.664,240.011 L376.388,112.436 C368.053,104.186 354.573,104.293 346.323,112.649 C338.073,121.005 338.158,134.464 346.536,142.714 L438.921,233.887 L191.362,233.887 C179.604,233.887 170.1,243.391 170.1,255.149 C170.1,266.907 179.604,276.412 191.362,276.412 L438.921,276.412 L346.536,367.585 C338.159,375.835 338.095,389.294 346.323,397.65 C350.49,401.86 355.976,403.986 361.462,403.986 C366.863,403.986 372.263,401.945 376.388,397.862 L505.664,270.287 C509.704,266.29 512,260.846 512,255.148 C512,249.452 509.725,244.03 505.664,240.011 Z" id="Path"></path>
                    </g>
                </g>
            </svg>
        </div>
    )
}

LogoutIcon.propTypes = {
    colour: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onClick: PropTypes.func,
    style: PropTypes.object,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

LogoutIcon.defaultProps = {
    colour: "currentColor",
    height: "22px",
    onClick: () => {},
    style: {},
    width: "22px", 
}

export default LogoutIcon;