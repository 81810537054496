// Packages
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Components
import ClearIcon from 'components/BTDUI/icons/ClearIcon';
import Skeleton from 'components/BTDUI/Skeleton';
import BTDTextField from 'components/BTDUI/fields/BTDTextField';
import UserInitials from 'components/BTDUI/UserInitials';
import BTDMenu from 'components/BTDUI/BTDMenu';

// Context
import { useProject } from 'context/Project';
import { useUser } from 'context/User';

// Other
import './styles.css'
import useApi from 'hooks/useApi';
import BTDNumberField from 'components/BTDUI/fields/BTDNumberField';
import BTDButton from 'components/BTDUI/BTDButton';
import BTDDatepicker from 'components/BTDUI/fields/BTDDatepicker';
import dayjs from 'dayjs';

const RecurringTaskListItem = ({ create, id, interval, intervals, is_active, isLoading, last_creation_at, name, next_creation_at, onChange, onSubmit, period, project, project_id, start_from, user, user_id }) => {

	const { projects } = useProject();
	const { users } = useUser();

	const { destroy, errors, put } = useApi('/recurringtasks/' + id + '?with=user,project,interval', false);
	const { post } = useApi('/recurringtasks', false);

	const deleteItem = async () => {

		if( await destroy('recurring task', 'Recurring Task Deleted!') ) {

			onChange();
		}
    }


	const [nameFormActive, setNameFormActive] = useState(false);
	const [periodFormActive, setPeriodFormActive] = useState(false);
	const [data, setData] = useState({})
	const [changedData, setChangedData] = useState({})

	useEffect(() => {

		setData({
			...data, 
			interval: interval,
			is_active: is_active,
			last_creation_at: last_creation_at,
			name: name,
			next_creation_at: next_creation_at,
			period: period,
			project_id: project_id,
			start_from: start_from,
			user_id: user_id
		});

	// eslint-disable-next-line
	}, [interval, is_active, last_creation_at, name, next_creation_at, period, project, start_from, user])


	return (
		<>
		<div className="RecurringTaskListItem card">

			<div>
				{! create && 
				<>
					<BTDMenu 
						icon={<p  style={{color: project ? project.colour : '#777'}}>{project ? project.name : 'Select project'}</p>} 
						iconStyle={{justifyContent: 'left', width: 'auto'}}
						style={{}}
					>
						{projects && projects.map((project) => (
							<p
								style={{color: project.colour ?? '#777'}}
								onClick={async () => {
									if ( await put({project_id: project.id}, 'Project updated!') ) {
										onChange();
									}
								}}
							>
								{project.name ?? ''}
							</p>
						))}
					</BTDMenu>
				</>}

				{nameFormActive || create ?
					<form className='RecurringTaskListItem__form' onSubmit={async (e) => {
						e.preventDefault();
						if(create) {
							if( await post({name: changedData.name}, 'Recurring Task created!') ) {
								onSubmit();
								setNameFormActive(false);
							}
						}
						else {
							if ( await put({name: changedData.name}, 'Recurring Task updated!') ) {
								onChange();
								setNameFormActive(false);
							}
						}
					}}>
						<BTDTextField 
							error={errors && errors.name ? errors.name : null} 
							height='46px'
							onChange={(value) => {
								setData({...data, name: value});
								setChangedData({...changedData, name: value});
							}} 
							placeholder='Name'
							style={{margin: '10px 0', maxWidth: '500px'}}
							value={data.name ?? ''} 
						/>
					</form>
				:
					<h3 className='RecurringTaskListItem__text black semi-bold' onClick={() => setNameFormActive(true)}>{isLoading ? <Skeleton width='200px'/> : name}</h3>
				}
			</div>


			{! create &&
			<>
					<BTDDatepicker
						error={errors && errors.start_from ? errors.start_from : null} 
						height='slim'
						label='Start From'
						onChange={async (value) => {
							setData({...data, start_from: value});
							setChangedData({...changedData, start_from: value});
							if( await put({start_from: value}, 'Start From updated!') ) {
								onChange();
							}
						}} 
						style={{margin: '10px 0', maxWidth: '200px', width: '100%'}}
						type='date'
						value={data.start_from ?? dayjs()} 
					/>

					<div>
						<p className='opacity centre'>Last Creation At</p>
						<p className='centre semi-bold'>{last_creation_at ? dayjs(last_creation_at).format('D MMM YYYY HH:mm') : ' '}</p>
					</div>

					<div>
						<p className='opacity centre'>Next Creation At</p>
						<p className='centre semi-bold'>{next_creation_at ? dayjs(next_creation_at).format('D MMM YYYY HH:mm') : ' '}</p>
					</div>

				<div>
					<p className='opacity centre'>Period</p>
					{periodFormActive ?
						<form className='RecurringTaskListItem__form' onSubmit={async (e) => {
							e.preventDefault();
							if ( await put({period: changedData.period}) ) {
								onChange();
								setPeriodFormActive(false);
							}
						}}>
							<BTDNumberField
								error={errors && errors.period ? errors.period : null} 
								height='slim'
								onChange={(value) => {
									setData({...data, period: value});
									setChangedData({...changedData, period: value});
								}} 
								placeholder='period'
								style={{margin: '10px 0', maxWidth: '100px', width: '100%'}}
								value={data.period ?? ''} 
							/>
						</form>
					:
						<p className='RecurringTaskListItem__text black semi-bold centre' onClick={() => setPeriodFormActive(true)}>{isLoading ? <Skeleton width='20px'/> : period ? period : 'Set period'}</p>
					}
				</div>

				<div className="RecurringTaskListItem__interval">
					<p className='opacity centre'>Interval</p>
					<BTDMenu 
						icon={<p className={`RecurringTaskListItem__interval__icon semi-bold`}> {interval ? interval.name : <Skeleton width='50px' />}</p>}
						iconStyle={{height: 'auto', width: 'auto'}}
					>
						{intervals && intervals.map((interval) => (
							<p 
								key={interval.id} 
								onClick={async () => {
									if( await put({interval_id: interval.id}, 'Interval updated!') ) {
										onChange();
									}
								}}
								>
								{interval.name}
							</p>
						))}
					</BTDMenu>
				</div>

				<div className="RecurringTaskListItem__user">
					<BTDMenu 
						icon={<div className={`RecurringTaskListItem__user__icon`} style={{backgroundColor: user ? user.colour : '#fff'}}>{user ? <UserInitials name={user.name}/> : <Skeleton borderRadius='50%' height='35px' width='35px' />}</div>}
						iconStyle={{height: 'auto', width: 'auto'}}
					>
						{users && users.map((user) => (
							<p 
								key={user.id} 
								onClick={async () => {
									if( await put({user_id: user.id}, 'Task assigned!') ) {
										onChange();
									}
								}}
								>
								{user.name}
							</p>
						))}
					</BTDMenu>
				</div>

				<BTDButton
					colour={is_active ? 'green' : 'red'}
					label={is_active ? 'Active' : 'Paused'}
					onClick={async () => {
						if( window.confirm('Are you sure you want to ' + (is_active ? 'pause' : 'activate') + ' this recurring task?') ) {

							if( await put({is_active: ! is_active}, is_active ? 'Recurring Task paused' : 'Recurring Task activated!') ) {
								onChange();
							}}
						}
					}
					size='small'
					type='outlined'
				/>

				<div onClick={() => {deleteItem()}}><ClearIcon colour='#e02323' height='20px' style={{cursor: 'pointer'}}/></div>
			</>
			}

		</div>

		</>
	)
}

RecurringTaskListItem.propTypes = {
	create: PropTypes.bool,
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	isLoading: PropTypes.bool,
	name: PropTypes.string,
	onChange: PropTypes.func,
	onSubmit: PropTypes.func,
}

RecurringTaskListItem.defaultProps = {
	create: false,
	id: null,
	isLoading: false,
	name: null,
	onChange: () => {'onChange is not set!'},
	onSubmit: () => {'onSubmit is not set!'},
}

export default RecurringTaskListItem;