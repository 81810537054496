// Packages
import PropTypes from 'prop-types';

// Components
import Skeleton from 'components/BTDUI/Skeleton';
import Tab from 'components/BTDUI/Tab';
import Avatar from 'components/BTDUI/Avatar';

// Other
import './styles.css';
import SpeechIcon from 'assets/kakao-talk.svg';
import NoSpeechIcon from 'assets/no-comment.svg';
import useApi from 'hooks/useApi';

const TaskListItem = ({ daily_update, id, isLoading, name, onChange, onItemClick, project, user, status }) => {

	const { put } = useApi('/tasks/' + id, false);

	return (
		<>
		<div 
			className="TaskListItem card" 
			draggable="true" 
            onDragStart={(e) => {e.dataTransfer.setData('application/json', JSON.stringify({id: id}))}}
			onClick={() => {
				onItemClick(id);
			}}
			onDragOver={(e) => {
				e.preventDefault();
				e.currentTarget.style.transform = 'scale(1.1)';
			}}
			onDragLeave={(e) => {
				e.preventDefault();
				e.currentTarget.style.transform = 'scale(1)';
			}}
			onDrop={async (e) => {
				e.preventDefault();
				const data = e.dataTransfer.getData('application/json');
				const formData = JSON.parse(data);
				e.currentTarget.style.transform = 'scale(1)';
				if( ! formData.id ) {
					if( await put(formData)) {
						onChange();
					}
				}
			}}
		>

			<div className="TaskListItem__top">

				<div className='TaskListItem__top__icons'>

					<p className='semi-bold' style={{color: project ? project.colour : '#222'}}>{! isLoading && project ? project.name : ''}</p>

					<img 
						alt='Daily Update icon'
						className='TaskListItem__top__icon'
						onClick={async (e) => {
							e.preventDefault();
							e.stopPropagation();
							await put({daily_update: daily_update == 'yes' ? 'no' : 'yes'}, daily_update == 'yes' ? 'Removed from daily update' : 'Added to daily update');
							onChange();
						}}
						src={daily_update == 'yes' ? SpeechIcon : NoSpeechIcon} 
					/>
				</div>


				<h4 className='TaskListItem__top__name black semi-bold'>{! isLoading ? name : <Skeleton width='100%' />}</h4>
			</div>

			<div className="TaskListItem__bottom">
				{! isLoading && user ?
					<Avatar user={user} size={35} withDropDown={false} />
					:
					<Skeleton borderRadius='50%' height='35px' width='35px'/>
				}

				{! isLoading && status ?
					<Tab text={status.name} colour={status.colour} style={{fontSize: '14px'}}/>
					:
					<Skeleton borderRadius='50px' height='28px' width='100px'/>
				}
			</div>

		</div>

		</>
	)
}

TaskListItem.propTypes = {
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	isLoading: PropTypes.bool,
	name: PropTypes.string,
	onChange: PropTypes.func,
	project: PropTypes.object,
	status: PropTypes.object,
	user: PropTypes.object,
}

TaskListItem.defaultProps = {
	id: null,
	isLoading: false,
	name: null,
	onChange: () => {'onChange is not set!'},
	project: {},
	status: {},
	user: {},
}

export default TaskListItem;